import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo } from "gsap"
import { Link } from "gatsby"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    gsap.from(document.querySelectorAll(".ruler_text_box"), {
      opacity: 0,
      duration: 1,
      delay: 1,
    })
    gsap.from(document.querySelector(".page_title_ani_wrap"), {
      width: 0,
      duration: 0.5,
      ease: Expo.easeOut,
      delay: 0.5,
    })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center chapter_b1">
          <div className="chapter_title">
            <Link to="/B1_1" className="waist">
              <ChapterTitle
                type="sec"
                StrokeColor="#87611A"
                FontColor="#00B0EC"
                Title="腰"
                Note="20歲以上"
              />
            </Link>
            <div className="page_title_ani_wrap">
              <div className="page_title sty_c">學習測量</div>
            </div>
            <Link to="/B1_2" className="calf">
              <ChapterTitle
                type="tri"
                StrokeColor="#87611A"
                FontColor="#F38CB7"
                Title="小腿"
                Note="50歲以上"
              />
            </Link>
          </div>
        </div>
      </>
    )
  }
}

export default Page
