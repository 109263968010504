import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import { gsap, Expo } from "gsap"

const ChapterTitle = props => {
  useEffect(() => {
    var ruler_text_box = document.querySelectorAll(".ruler_text_box")

    const active_start = function(event) {
      this.classList.add("active_start")
      // console.log("hover")
    }
    const active_end = function(event) {
      this.classList.remove("active_start")
      this.classList.add("active_end")
      setTimeout(() => {
        this.classList.remove("active_end")
      }, 900)
    }

    // ruler_text_box.addEventListener("mouseenter", active_start)
    // ruler_text_box.addEventListener("mouseleave", active_end)
    // ruler_text_box.addEventListener("touchstart", active_start)
    // ruler_text_box.addEventListener("touchend", active_end)

    ruler_text_box.forEach(b => b.addEventListener("mouseenter", active_start))
    ruler_text_box.forEach(b => b.addEventListener("mouseleave", active_end))
    ruler_text_box.forEach(b => b.addEventListener("touchstart", active_start))
    ruler_text_box.forEach(b => b.addEventListener("touchend", active_end))

    return () => {
      // unsubscribe event
      // div.removeEventListener("scroll", handleOnScroll)
    }
  }, [])

  // gsap.from(document.querySelectorAll(".ruler_text_box"), {
  //   opacity: 0,
  //   duration: 1,
  // })

  // const [hovered, setHovered] = useState(false)
  // const toggleHover = () => setHovered(!hovered)
  if (props.type == "pri") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="580"
        height="203"
        viewBox="0 0 580 203"
        className="ruler_text_main ruler_text_box"
        // className={`ruler_text_main ruler_text_box ${hovered ? "active" : ""}`}
        // onMouseEnter={toggleHover}
        // onMouseLeave={toggleHover}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(1 -15)">
            <g
              stroke={props.StrokeColor}
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(4 16)"
            >
              <path
                className="line_b"
                d="M.009 146.374c-2.621-113.9 558.412-177.89 565.379-73.715"
              ></path>
              <path
                className="line_b_2"
                d="M.009 126.374c-2.621-113.9 558.412-177.89 565.379-73.715"
              ></path>
            </g>
            <text
              fill={props.FontColor}
              fontFamily="NotoSansTC-Black, Noto Sans TC"
              fontSize="140"
              fontWeight="700"
              className="text"
            >
              <tspan x="3" y="162">
                {props.Title}
              </tspan>
            </text>
            <g
              stroke={props.StrokeColor}
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(4 70)"
            >
              <path
                className="line_b"
                d="M565.38 0C568 113.901 6.966 177.89 0 73.715"
              ></path>
              <path
                className="line_b_2"
                d="M565.38 20C568 133.901 6.966 197.89 0 93.715"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.type == "sec") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="233"
        height="350"
        viewBox="0 0 233 300"
        className="ruler_text_sec ruler_text_box"
        // className={`ruler_text_sec ruler_text_box ${
        //   hovered ? "active_start" : "active_end"
        // }`}
        // onMouseEnter={toggleHover}
        // onMouseLeave={toggleHover}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(1 0)">
            <g
              stroke={props.StrokeColor}
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(1 65)"
              className="line_back"
            >
              <path
                className="line_a line_upper"
                d="M227.4691,18.4999 C202.2331,-26.0001 1.5471,18.9389 0.0001,57.1959"
              ></path>
              <path
                className="line_b line_lower"
                d="M1.2313,74.8241 C-7.6387,19.8891 221.13,-17.435 230,37.5"
              ></path>
            </g>
            <text
              fill={props.FontColor}
              fontFamily="NotoSansTC-Black, Noto Sans TC"
              fontSize="144"
              fontWeight="700"
              className="text"
            >
              <tspan x="43" y="167">
                {props.Title}
              </tspan>
            </text>
            <g
              stroke="#835F26"
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(0 88)"
              className="line_front"
            >
              <path
                className="line_a line_lower"
                d="M2.9169,56.3245 C28.1529,100.8245 228.8389,55.8855 230.3859,17.6285"
              ></path>
              <path
                className="line_b line_upper"
                d="M229.155 0C238.025 54.935 8.87 91.935 0 42.5"
              ></path>
            </g>
            <text
              transform="translate(119.500000, 227.000000) rotate(-360.000000) translate(-119.500000, -227.000000) "
              fontFamily="NotoSansTC-Bold, Noto Sans TC"
              fontSize="24"
              fontWeight="bold"
              letterSpacing="2"
              fill={props.FontColor}
            >
              <tspan x="64" y="237">
                {props.Note}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    )
  } else if (props.type == "tri") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="232"
        height="400"
        viewBox="0 0 232 500"
        className="ruler_text_tri ruler_text_box"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(0 -20)">
            <g
              stroke="#805E26"
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(1 143)"
              className="line_back"
            >
              <path
                className="line_a line_upper"
                d="M227.47 18.5C202.232-26 1.546 18.939 0 57.196"
              ></path>
              <path
                className="line_b line_lower"
                d="M1.231 74.824C-7.639 19.89 221.13-17.435 230 37.5"
              ></path>
            </g>
            <text
              fill={props.FontColor}
              fontFamily="NotoSansTC-Black, Noto Sans TC"
              fontSize="144"
              fontWeight="700"
            >
              <tspan x="43" y="167">
                小
              </tspan>
              <tspan x="43" y="331">
                腿
              </tspan>
            </text>
            <g
              stroke="#835F26"
              strokeDasharray="40,15"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="translate(0 176)"
              className="line_front"
            >
              <path
                className="line_a line_lower"
                d="M2.917 56.325c25.236 44.5 225.922-.44 227.469-38.697"
              ></path>
              <path
                className="line_b line_upper"
                d="M229.155 0C238.025 54.935 9.37 91.935.5 37"
              ></path>
            </g>
            <text
              // transform="translate(119.500000, 407.000000) rotate(-360.000000) translate(-119.500000, -227.000000) "
              fontFamily="NotoSansTC-Bold, Noto Sans TC"
              fontSize="24"
              fontWeight="bold"
              letterSpacing="2"
              fill={props.FontColor}
            >
              <tspan x="64" y="420">
                {props.Note}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    )
  }
}

export default ChapterTitle
