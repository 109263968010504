export default {
  title: {
    placeholder: "爺爺",
    list: [
      "阿祖",
      "爺爺",
      "阿公",
      "奶奶",
      "阿嬤",
      "爸爸",
      "媽媽",
      "伯父",
      "伯母",
      "叔叔",
      "嬸嬸",
      "舅舅",
      "舅媽",
      "姑姑",
      "姑丈",
      "阿姨",
      "姨丈",
      "哥哥",
      "姊姊",
      "老師",
      "其他",
    ],
  },
  gender: { placeholder: "男性", list: ["男性", "女性"] },
  age: {
    defaultValue: 60,
    max: 110,
    min: 20,
  },
  waist: { defaultValue: 60, max: 200, min: 40 },
  calf: { defaultValue: 60, max: 60, min: 20 },
  quiz_waist_male: { defaultValue: "<90", max: 120, min: 60 },
  quiz_waist_female: { defaultValue: "<80", max: 120, min: 60 },
}
