import React, {
  Suspense,
  // useRef,
  // useState,
  // useCallback,
  useEffect,
} from "react"
import PropTypes, { arrayOf } from "prop-types"
import { Match } from "@reach/router"
import { Transition } from "react-transition-group"
import { gsap, Expo, Power2, Back, Power3 } from "gsap"
import InnerHeight from "ios-inner-height"
import { isMobile } from "react-device-detect"
import window from "global"

import {
  START_TRANSITION,
  END_TRANSITION,
  ContextStore,
} from "../../hooks/useContextSPATransitionLock.js"

import sty from "./TransitionMatchHOC.module.scss"

const TransitionMatchHOC = ({ children, path }) => {
  const { dispatchTransitionType } = React.useContext(ContextStore)

  // var Wrap_InnerHeight = {
  //   width: window.innerWidth + 'px',
  //   height: InnerHeight() + 'px',
  //   overflowX: 'hidden'
  // };

  const Wrap_InnerHeight = {
    width: window.innerWidth + "px",
    height: window.innerHeight + "px",
    // overflowX: "hidden",
  }

  // var wrap = document.querySelector('#wrap');
  // console.log(InnerHeight);
  // document.querySelector('#wrap').style.height = InnerHeight() + 'px';

  return (
    <Match path={path}>
      {({ match }) => {
        // console.log(match)

        return (
          <Transition
            in={match !== null}
            // timeout={1000}
            mountOnEnter
            unmountOnExit
            addEndListener={(node, done) => {
              if (match !== null) {
                dispatchTransitionType({ type: START_TRANSITION })
                gsap.from(node, {
                  zIndex: 1,
                  duration: 1,
                  opacity: 0,
                  delay: 0.4,
                  // translateX: "10%",
                  // scale: "1.1",
                  ease: Expo.easeOut,
                  onComplete: () => {
                    dispatchTransitionType({ type: END_TRANSITION })
                    done()
                  },
                })

                var chapter_title = node.querySelector(".chapter_title")
                if (chapter_title) {
                  gsap.from(chapter_title, {
                    opacity: 0,
                    scale: 0.9,
                    duration: 2,
                    translateY: "50%",
                    // delay: 0.5,
                    ease: Expo.easeOut,
                  })
                }

                var page_nav = node.querySelector(".page_nav")
                if (page_nav) {
                  gsap.from(page_nav, {
                    opacity: 0,
                    duration: 1,
                    translateY: "50%",
                    delay: 0.5,
                    ease: Expo.easeOut,
                  })
                }
                // var chapter_page_header = node.querySelector(
                //   ".chapter_page_header"
                // )
                // if (chapter_page_header) {
                //   console.log("page_title")
                //   gsap.from(chapter_page_header, {
                //     opacity: 0,
                //     translateX: "-50%",
                //     translateY: "15%",
                //     duration: 0.5,
                //     ease: Expo.easeOut,
                //     delay: 0.5,
                //   })
                // }
              } else {
                gsap.set(node, {
                  clearProps: "zIndex",
                })
                gsap.to(node, {
                  opacity: 0,
                  duration: 1,
                  // scale: ".9",
                  // translateX: "-10%",
                  ease: Expo.easeOut,
                  onComplete: () => {
                    dispatchTransitionType({ type: END_TRANSITION })
                    done()
                  },
                })

                document.querySelector(".background").classList.remove("hero")
                var background_ruler = gsap.timeline()
                var cover_ruler = document.querySelectorAll(".cover_ruler")
                background_ruler
                  .to(cover_ruler, {
                    backgroundPosition: "200% 0%",
                    duration: 3,
                    ease: Power2.easeOut,
                  })
                  .set(cover_ruler, {
                    backgroundPosition: "0% 0%",
                  })
              }
            }}
          >
            <div
              id="wrap"
              className={sty.TransitionMatchHOC}
              style={isMobile ? Wrap_InnerHeight : null}
            >
              {children}
            </div>
          </Transition>
        )
      }}
    </Match>
  )
}

TransitionMatchHOC.propTypes = {
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

export default TransitionMatchHOC
