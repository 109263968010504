import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo, TimelineMax, Power2 } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const taiwan_map = document.querySelector(".taiwan_map")
    const map_info = taiwan_map.querySelector(".map_info")
    const map_point = taiwan_map.querySelectorAll(".map_point")
    const map_cursor = document.querySelector(".map_cursor")
    // console.log(map_point)

    for (let i = 0; i <= map_point.length - 1; i++) {
      const title = document.querySelector(".title")
      const school_num = document.querySelector(".school_num")
      const people_num = document.querySelector(".people_num")

      const taiwan_map_active_start = function(event) {
        title.innerHTML = this.dataset.title
        school_num.innerHTML = this.dataset.school
        people_num.innerHTML = this.dataset.people
        map_info.classList.remove("map_active_end")
        map_info.classList.add("map_active_start")
        this.classList.add("active")

        // map_info.style.top = event.pageY + "px"scrollTop
        // map_info.style.left = event.pageX + "px"
      }
      const taiwan_map_active_end = function(event) {
        this.classList.remove("active")
      }
      map_point[i].addEventListener("mouseenter", taiwan_map_active_start)
      map_point[i].addEventListener("mouseleave", taiwan_map_active_end)
      map_point[i].addEventListener("touchstart", taiwan_map_active_start)
      map_point[i].addEventListener("touchend", taiwan_map_active_end)
    }

    const map_info_active_end = function(event) {
      map_info.classList.remove("map_active_start")
      map_info.classList.add("map_active_end")
    }
    taiwan_map.addEventListener("mouseleave", map_info_active_end)
    taiwan_map.addEventListener("touchend", map_info_active_end)

    taiwan_map.addEventListener("mousemove", function() {
      var event = event || window.event
      var cursor_animate = new TimelineMax()
      cursor_animate.to(map_info, 0.3, {
        top: event.clientY + 40,
        left: event.clientX + 40,
        ease: Power2.easeOut,
        delay: 0.1,
      })

      var map_cursor_animate = new TimelineMax()
      map_cursor_animate.to(map_cursor, 0.3, {
        top: event.clientY - 48,
        left: event.clientX - 48,
        ease: Power2.easeOut,
      })
    })
    // taiwan_map.addEventListener("mouseleave", taiwan_map_active_end)
    // taiwan_map.addEventListener("touchstart", taiwan_map_active_start)
    // taiwan_map.addEventListener("touchend", taiwan_map_active_end)

    // gsap.from(start_anime, {
    //   opacity: 0,
    //   scale: 0.8,
    //   translateX: "50%",
    //   translateY: "50%",
    //   direction: 2,
    //   ease: Expo.easeOut,
    //   delay: 0.3,
    // })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap mobile_column">
          <article className="document_wrap">
            <h1>歷年成果頁面</h1>
            <h4>計畫說明</h4>
            <p>
              自2012年起，千禧之愛健康基金會希望藉由國小學童將代謝症候群與肌少症的防治觀念傳遞回家庭。透過教師培訓、OPEN劇團校園演出以及親子衛教學習單，深入小學倡導代謝症候群防治觀念。
            </p>
            <p>
              從2012-2016年，第一代「千禧 OPEN
              小將，健康出動到校園」，持續到校園與學童互動，2017年起更增加縣市衛生局合作，發送紙本學習單的暑期量腰作業，2020年「家庭健康長」將暑期作業數位化，並重啟「健康出動到校園」。
            </p>
            <p>累計至2022年共走訪了21縣市72所國小，觸及人數達54萬8千人次。</p>
            <hr />
            <h4>觸及學校與人數</h4>
            <p>
              總共觸及 72 間學校，548,549 人次<strong>（互動地圖）</strong>
            </p>
            <div className="map_cursor"></div>
            <div className="taiwan_map">
              <div className="map_info">
                <h5 className="title">雲林縣</h5>
                <div className="info_list">
                  <div className="school info_item">
                    <h6>學校</h6>
                    <div className="school_num number">123</div>
                  </div>
                  <div className="people info_item">
                    <h6>人數</h6>
                    <div className="people_num number">123</div>
                  </div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="781"
                height="1066"
                viewBox="0 0 781 1066"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="#87611A"
                  strokeWidth="1"
                >
                  <path
                    fill="#FFF41F"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="none"
                    d="M505.3 260L505.1 260.2 500.5 261.3 496.1 259.6 493.4 255.8 489.1 253.2 482.2 250.5 483.4 246.4 488.9 235 491.1 232 491.8 230.5 491.8 228.8 491.1 226.9 490 224.7 491.5 221.7 493.3 219.9 501.6 223.5 516.7 228.3 522.1 231.1 525.9 235 527.8 238.7 526.8 242.3 524.4 243.7 519.3 244.6 513.4 247 510 251.3 507.7 256.9 505.3 260z"
                  ></path>
                  <circle
                    cx="675.05"
                    cy="129.05"
                    r="1"
                    fill="#FFF41F"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <circle
                    cx="450.95"
                    cy="297.15"
                    r="1"
                    fill="#FFF41F"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <circle
                    cx="350.75"
                    cy="792.45"
                    r="1"
                    fill="#FFF41F"
                    fillRule="nonzero"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <g
                    transform="translate(230 1)"
                    className="map_point"
                    data-title="連江縣"
                    data-school="2"
                    data-people="1227"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20.1 122.9l-.2 2.3-1.9 2.1-4.1 2 2.5-5.6 2.5.5.1-.9 1.1-.4zm-11.8-.8l-2 2.1-2.4-3.4 3.6-1.3 2.7 1.5-1.9 1.1zM5.1 67.2l1 .5 4.4-2.6h2.8v1.8l-2.8 3.2L5 73l-1.7.3-2.5-1.7-.8-2.4.5-2.8 1.1-2 1-.2.3 2.2 2.2.8zm21.3-17.8l-.6 2.2.6 1.2-1.4-.2-1.5-2.2-1.1-.7-2.3 1.3-1-.4-1.3.3-1.5 1.7-1.2 5-1.1-.7-.5-1.5.5-4.3.5-1.6 1.1-.7 2.7-.9.7-.9 2.2-1.3 2.5.4L25 48l1.4 1.4zM156.2 3.8l-1.2.4-1.1-.4-1.4 1.3-1.1 2.1-.3-.5-1.2-.2-1.8-1 1.1-1.7-.5-1.4.9-.6 2.2-1.8 2.6 1.5.6 1.3 1.2 1z"
                    ></path>
                    <circle
                      cx="31"
                      cy="65"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(1 266)"
                    className="map_point"
                    data-title="金門縣"
                    data-school="3"
                    data-people="7509"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8.2 84.8L3 86.3l-3-1.2 5-9.9 1.3-1.4 2.1-.5 2.1-.1 2 1.4 1.6 1.8v3.1l-1.4 2.1-1.6.1-1.1 1.2-.6 1.2-1.2.7zm55-26.8l4.7 12.6-1 8.8L63 84l-6.8-4.3-5-1.6-8.1.8-7.1 4.5-3.9 6.1-3.4 1.7-3.9-1.9-2.7-.1-2.4-1.1-1.2-2.1.5-.7 2.1-2.8.7-1.2.2-2.1-.1-2.5-.7-4.3-1.8-4.2-.2-2.1 1.5-.9 7.6-3.7 10 6.6 7-.9 1.1-8.7 2.3-5.5 7.5-1.5 7 6.5zm130-53.7l-2.9 1.5-1.3-1.2.7-3.2 2.4-1.4 1.7.3.2 1.9-.8 2.1z"
                    ></path>
                    <circle
                      cx="57"
                      cy="97"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(70 535)"
                    className="map_point"
                    data-title="澎湖縣"
                    data-school="3"
                    data-people="6240"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M31.7 162.5l-1.8.9-1.3-1.5-1.5-4.7 1-1.2 3.8-1.3 2.7.7.1 2.1-.2 1.6-1.2 1.2-1.6 2.2zm-1.2-12l-1.7 3.1-2.2-.6-2-2.5-.3-2.5 1.2-1.1 3.2.5 1.8 3.1zm48.8-3.2l-.4.1-1.2-1.5.3-.6 2.5-.2.8 2-2 .2zm14-4.6l.3.2h2.3v1.3l-1.1 1.5-.7 1.8-1.1-2.8.3-2zm-33.5-29.6l-1.7.1-3 1.8-.4-.4.4-1.3 1.1-1.5.7-.6 1.6-.3 1.5.9-.2 1.3zm-8.4-6.7l.1 2.3.5 1.6 1.4 1.2-.6 1.6-1.2 1.2.6 1.9-1.5.6-3.1-.3-1.6-.7 1.4-3.1-.2-4.2-.9.6-2 .6-.6-2.3 2.1-5.1 1.6-.3 2.7 3.6 1.3.8zm-49.5-5.7l2.4.6v1.6l-.6.9-2.1.6-1.1-1.1 1.4-2.6zm58.5-24.8L57.2 78l-2.1.6-2.2 1.1-1.3-.5-.3-1.8.7-.6 1.8.5 4-2 2.6.6zm19.5-28l-.6 1.3 4-1.3 3.1.4 2.3-.2 1.6-2.7 4.7 8.7 1.2 4-.5 3-2-2-2.5-1.7-2.8-.5-4 2.2-2.8.3-1.4.8-.7 1.5-1.1 4.1-.7 1.2-4.7 2.7-4.8.7-4.8-1.9-4.9-4.9 1.3-2.5 2.7 3.2 4 .9 4.3-1.2 3.5-2.9-2.4-.9-2-1.2-1.5-1.6-1.4-2.2-.8 1.4-1 .6-.3-4 .3-4H67l2.1.5 7.4-4.6 4.6-1.6-1.2 4.4zM40 57.2h-3.8l.7-2.1 1.2-1.3 1.9-.5 2.5-.2 2.2-.9.6-2 .1-4.8L48.6 35l3.1-3 4.2 3.6-2.5 1.5-1.7 3.6-2.8 8.5-.1 2.2.7 2-.3 1.3-3 .5-2.2.1-1.5.3-1.3.6-1.2 1zm33.8-17.9l-2.2.7-1.1-1.5-.9-3.5-2-2.9-4.3-4.3 5.5-4.1 3.3 1.7 3.2 2.8 1.4 2.9-2.5 2.4 1 3.5-1.4 2.3zm2.6-30.4l-.3.9-1.3-3-.2-1.4 1.8-1.5 1.6-3 .5 1.1L81 3.4l-.4 1.8-2.2 1.3-2 2.4z"
                    ></path>
                    <circle
                      cx="90"
                      cy="31"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(637 117)"
                    className="map_point"
                    data-title="台北市"
                    data-school="8"
                    data-people="124839"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M41.4 67.2L37.2 68.9 31.4 68.8 26.6 67.8 22.9 64.5 19.1 60.2 13.3 55.6 9.4 48.9 10.9 41.5 9.7 35.2 4 29.7 0.2 25.5 1.8 22.5 3.6 20.1 5.7 15.5 9.3 11.5 20.2 5.1 23.1 2.6 27.1 0.6 30.9 1.8 33 4 32.5 7.6 32.9 11.1 36.1 17.9 38.6 27.9 42.5 31.4 44.1 35.8 42.5 42.4 42.6 47.5 47.2 50.1 51 53.3 49 57.6 41.4 67.2z"
                    ></path>
                    <circle
                      cx="25"
                      cy="38"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(591 93)"
                    className="map_point"
                    data-title="新北市"
                    data-school="9"
                    data-people="16449"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M105.9 35.2l-4.4 2.9-7.2 3.1-3.2 2.2.6 2.9 2.9 3.7 1.7 3.5 2.8 3.5 3.7 3.4 3 2.2 10.6 4.4 5.1.6 4-1.5 2.5-1.8.4-2.5-3.7-3.9-.2-3 .3-11.9 34.6 8.4 3.2 2-1.6 3.7-.4 2.9.7 5.2 2.4 8.7 2.6 4.1 2.2.9 7-1 3.6.4 4.2 1.1 3.7 1.9 2 2.5-8.7 4.3-.8-1.1-3.3-1.9-5.9 1.3-8.4 4.4-3.7 2.5-11.2 2.3-1.4 2.9 2.5 3.6-1.6 3.8-4.6 2.9-3.5 1.2-3.7 2-3.3 4.1-3.1 5-5 3.6-6.2 1.9-13.5 8.1-13 5.3-3.8 2.3-4.7 4-2 4.9.2 4.2 1 3.2.4 2-3.9 2.8-6.6 3.2-5 3.5-3.8 3.3-4.9 1.8-5.7 1.6-2.5-5.4-8.9-8.5-1.4-5.6-.8-5.2 3.1-3.7 1.5-5.3-8.5-12.3-5-2-7.4.4-5.1-2.9 1.3-6.7-1-5.1-5.4-5.1-1-5.1 1-5.8-.2-6.2 3.7-4 13.1-3.9 3.3-3.4 1-4.2-.5-4.1-5.1-7.5-4.2-2.2-4.1-1-3.2-2.8-4.7-3.1-6.3-3-4.2-4.6 11.2-2.1 4.8-1.8 6.1-5.2 1.9-.9 2.2-.4h3.1l3.5 1.1 1.9 2.7 1.2 2.7 1.5 1.6 3.5-1.4-3.6-5.7-5.8-5.9-3.1-1.9.9-2.7 1.8-1.5 1.9-.9.8-1 2.1-5.1 3.2-5.9L46 8.3l9.6-4.9 11-2.5 10.8 1.2 4.5 3.4 10 14.2 3.6 7.4 1.2-.5 2.7-.7 3-.4 2.2.6-.1.9-1.1 1.3-1 1.8.1 2.2 1.1 1.2 2.3 1.7zm-18.5 56l7.6-9.6 2-4.3-3.8-3.2-4.6-2.6-.1-5.1 1.6-6.6-1.6-4.4-3.9-3.5-2.5-10-3.2-6.8-.4-3.5.5-3.6-2.1-2.2-3.8-1.2-4 2-2.9 2.5-10.9 6.4-3.6 4-2.1 4.6-1.8 2.4-1.6 3 3.8 4.2 5.7 5.5 1.2 6.3-1.5 7.4 3.9 6.7 5.8 4.6 3.8 4.3 3.7 3.3 4.8 1 5.8.1 4.2-1.7z"
                    ></path>
                    <circle
                      cx="66"
                      cy="112"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(508 141)"
                    className="map_point"
                    data-title="桃園市"
                    data-school="4"
                    data-people="128082"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M134.8 128.8L131.7 129.7 126.3 133.6 129.4 146 127.2 149.8 123.8 151.1 120.5 150.5 116.6 152.1 116.1 153.6 111.3 151.4 104 147.3 100 144.4 94.5 143.5 88.3 139.7 83.1 135.8 82.6 131.5 87.2 125 89.1 117.2 87.5 109.9 87.1 104.9 83.7 102.3 79.4 100.1 76.8 95.6 73.4 92.1 69.5 92.4 65.7 91.7 62.6 88.6 58.4 85.5 51.9 83.4 48.4 79.7 49.5 73.9 45.9 69.7 39.1 67.5 35 65.5 30.7 64.3 25.7 62.3 21.7 53.6 17.1 50.5 3.4 51.5 0.3 50.4 9.2 36.2 16.1 27.9 23.3 21.8 41.7 15.7 46 13 49.7 9.8 54.2 7.3 63.2 4.1 83.3 0.5 87.5 5.1 93.8 8.1 98.5 11.2 101.7 14 105.8 15 110 17.2 115.1 24.7 115.6 28.8 114.6 33 111.3 36.4 98.2 40.3 94.5 44.3 94.7 50.5 93.7 56.3 94.7 61.4 100.1 66.5 101.1 71.6 99.8 78.3 104.9 81.2 112.3 80.8 117.3 82.8 125.8 95.1 124.3 100.4 121.2 104.1 122 109.3 123.4 114.9 132.3 123.4 134.8 128.8z"
                    ></path>
                    <circle
                      cx="62"
                      cy="43"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(493 191)"
                    className="map_point"
                    data-title="新竹縣"
                    data-school="1"
                    data-people="21795"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M131.1 103.6L128.8 110.1 127.4 114.8 128 119.2 124.2 125 110.2 139.2 105.9 149.7 103.1 148.9 92.5 149.9 91.7 150.5 91.1 145.6 86.5 137.8 83.2 134.5 81.9 130.1 76.9 126.1 64.4 129.3 59.6 128.8 52.8 129.3 45.1 131.4 41 130.4 41.9 125.3 43.2 120.7 42.2 116.7 41.2 113.8 42.2 110.7 43 107.2 43.3 103.3 42.6 98.1 38.7 94.7 26.6 89 20.6 84.5 16.7 80.1 12.3 69 14.7 65.9 17 60.3 20.4 56 26.3 53.6 31.4 52.7 33.8 51.3 34.8 47.7 32.9 44 29.1 40.1 23.7 37.3 8.6 32.5 0.3 28.9 1.8 26.6 2.4 22.7 3.3 19.8 5.3 16.9 8 14.8 10.5 13.9 10.3 12.7 11 9.8 13.2 3.7 15.3 0.4 18.4 1.5 32.1 0.5 36.7 3.6 40.7 12.3 45.7 14.3 50 15.5 54.1 17.5 60.9 19.7 64.5 23.9 63.4 29.7 66.9 33.4 73.4 35.5 77.6 38.6 80.7 41.7 84.5 42.4 88.4 42.1 91.8 45.6 94.4 50.1 98.7 52.3 102.1 54.9 102.5 59.9 104.1 67.2 102.2 75 97.6 81.5 98.1 85.8 103.3 89.7 109.5 93.5 115 94.4 119 97.3 126.3 101.4 131.1 103.6z"
                    ></path>
                    <circle
                      cx="65"
                      cy="78"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(416 250)"
                    className="map_point"
                    data-title="苗栗縣"
                    data-school="1"
                    data-people="6528"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M89.3 10L93.7 21.1 97.6 25.5 103.6 30 115.7 35.7 119.6 39.1 120.3 44.3 120 48.2 119.2 51.7 118.2 54.8 119.2 57.7 120.2 61.7 118.9 66.3 118 71.4 122.1 72.4 129.8 70.3 136.6 69.8 141.4 70.3 153.9 67.1 158.9 71.1 160.2 75.5 163.5 78.8 168.1 86.6 168.7 91.5 165 94.1 163.2 99.7 158.3 101.7 153 101.6 148.8 104.6 144.2 109 138.3 112.2 126.7 121.4 119.9 124.3 111 129 105.6 126.9 100.6 121.3 94.6 118.9 84.7 118.1 79.2 121.4 78.3 128.8 73.1 131.6 61 132.3 49.4 125.6 42.8 124.4 35.5 120.8 20.4 109.7 14.1 104.2 9.4 99.2 0.2 84.9 7.4 76.8 9.3 72.8 10.2 66.2 12.4 60.6 18.3 50.2 19.9 43.9 21.4 41.2 28.2 39.2 30.1 37.1 31.2 34.5 32.6 32 36.8 27.7 41.4 24.3 47 22.8 54.1 24.1 53.2 18.7 55.9 15 59.6 11.2 61.5 5.5 62.3 3.6 65.9 1.8 66.2 0.5 73.1 3.2 77.4 5.8 80.1 9.6 84.5 11.3 89.1 10.2 89.3 10z"
                    ></path>
                    <circle
                      cx="68"
                      cy="68"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(377 334)"
                    className="map_point"
                    data-title="台中市"
                    data-school="2"
                    data-people="73893"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M207.7 7.5L208.5 6.9 219.1 5.9 221.9 6.7 225.5 7.7 224.9 13.2 226.3 17 231.2 18 235.6 20.6 239.3 23.1 243.3 22.5 248 20.2 252.9 20.2 258.8 24.9 254.1 29.8 253.7 34 251.3 40.2 243.8 44.9 240.8 49.9 239.5 55 236.4 58.8 233 60.5 231.8 62.6 229 64.6 215.6 63.3 210.9 62.3 206.3 62.6 195.2 67 188.9 68 182.7 68.2 173.9 72.9 169.5 73.7 164.4 76.6 156.5 84.2 151.5 86.1 144.9 86 140.2 89.9 137.6 95.4 134.1 96.3 125.2 89.6 122.2 91.5 116.4 99.2 110.4 99.4 100.3 98 93.9 101 90.2 109.5 82.6 123.9 77.6 128.7 73.4 129.3 55.5 126.8 51.2 126.6 51.2 125.2 45.8 121.7 41.5 120.9 40.3 117.6 40.2 113.6 38.2 109.6 35 104.6 30.7 101 18.8 97.5 14.3 92.1 13.3 84.5 10.6 78.2 6.6 74.7 0.8 72.8 1.4 72 2.2 68.7 5.5 66 7.7 59.5 10.4 47.2 19.7 31.1 22.5 21.2 25.9 15.7 39.2 0.9 48.4 15.2 53.1 20.2 59.4 25.7 74.5 36.8 81.8 40.4 88.4 41.6 100 48.3 112.1 47.6 117.3 44.8 118.2 37.4 123.7 34.1 133.6 34.9 139.6 37.3 144.6 42.9 150 45 158.9 40.3 165.7 37.4 177.3 28.2 183.2 25 187.8 20.6 192 17.6 197.3 17.7 202.2 15.7 204 10.1 207.7 7.5z"
                    ></path>
                    <circle
                      cx="69"
                      cy="73"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(598 178)"
                    className="map_point"
                    data-title="宜蘭縣"
                    data-school="2"
                    data-people="3180"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M165.4 41.9l2.1.5 1.6 2.2-.7 2.3-3.8-.1-1.4-.8-1.7-2.7.9.3 3-1.7zm-47 152.6h-1.1l-11.1-1.7-18.5-11.1-3.8.9-2.4 6-4.9 4.5h-6.5l-8.3-3.2-15-3.8-6.5-3.1-2.5-2.1-5.9-4.7H27l-4.7 2.3-4 .6-3.7-2.5-4.4-2.6-4.9-1-1.4-3.8.6-5.5-3.6-1 4.3-10.5 14-14.2 3.8-5.8-.6-4.4 1.4-4.7 2.3-6.5.5-1.5 3.9-1.6 3.3.6 3.4-1.3 2.2-3.8-3.1-12.4 5.4-3.9 3.1-.9 5.7-1.6 4.9-1.8 3.8-3.3 5-3.5 6.6-3.2 3.9-2.8-.4-2-1-3.2-.2-4.2 2-4.9 4.7-4 3.8-2.3 13-5.3 13.5-8.1 6.2-1.9 5-3.6 3.1-5 3.3-4.1 3.7-2 3.5-1.2 4.6-2.9 1.6-3.8-2.5-3.6 1.4-2.9 11.2-2.3 3.7-2.5 8.4-4.4 5.9-1.3 3.3 1.9.8 1.1-12 6-5.5 4.7-18.7 22.8-2.9 4.8-2.3 6.8-1.6 7.7-.5 7.6.3 7.4 3.3 15.5-.2 4-1.5 3.9.2 8.1 3.5 6.3 6.5 4 7.6 1.4v1.8l-3.2-.3-3.1.3-2.6.8-1.9 1.4 2.7 5 1.3 6.5-.8 5.6-6 3.4-.4 2.5.7 3 1.7 2.7.6 2.8-2.5 2.4-6.2 3.6-7 8-.3 3.2.5 7-1.2 2.6-1.3 2.3-.8 3.2-.5 6.4-.6 1.6-1 1.3-.5 1.4 1.3 1.9z"
                    ></path>
                    <circle
                      cx="84"
                      cy="128"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(316 406)"
                    className="map_point"
                    data-title="彰化縣"
                    data-school="3"
                    data-people="33974"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M112.2 54.6L112.3 58.2 108 59.8 106.8 62.7 107.3 66.7 105.3 71.3 103.7 76.7 103.6 82.4 102.4 89 101.5 102.4 103.6 108.7 107.2 112 111.1 113.4 116.7 116.3 115.7 119.8 111.9 121.3 109.4 123.6 107 125.2 106 125.9 94 123.5 88.2 123.6 76 117.6 56.3 115.3 44.7 110.9 38.1 109.1 30.5 108.7 8.5 111.3 0.9 106.9 1.7 105.5 3.3 98.4 4.5 95.4 11.6 88.6 13.6 85.8 16.3 78 20.5 70.3 26.2 54.2 28.9 49.5 31.9 46.5 34.4 44.8 36.6 43.7 38.2 42.3 38.8 39.3 40 36.5 45.1 32.5 46.3 29.5 46.9 19.4 47.8 16.6 50.5 14 61.8 0.8 67.6 2.7 71.6 6.2 74.3 12.5 75.3 20.1 79.8 25.5 91.7 29 96 32.6 99.2 37.6 101.2 41.6 101.3 45.6 102.5 48.9 106.8 49.7 112.2 53.2 112.2 54.6z"
                    ></path>
                    <circle
                      cx="62"
                      cy="63"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(285 512)"
                    className="map_point"
                    data-title="雲林縣"
                    data-school="2"
                    data-people="2811"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M138 19.2L139.9 26.4 137.9 31.1 136.9 38.3 137.9 46 136 51.2 135.1 56.9 139.1 61.6 144.9 64.1 151.7 62.1 159.9 61.2 162.5 64.7 162 64.7 161.7 69.7 161.7 73.1 157.7 74.4 146 76.8 139.7 77.4 137.3 73.5 132.6 70.1 124.3 72.5 120.6 73 108 68.4 103 62.5 97.7 57.9 90.8 58.3 78.2 60.6 71 62.6 54.7 72 50.9 75.8 46.5 78.2 39.5 83.9 34.2 84.7 32.1 87.7 30.4 92.6 26.5 95.8 20.4 95.7 12.1 91 0.5 92.4 5.1 87.2 4.9 70.8 5.6 58.5 8.4 48.7 12.7 41 14 31 16.3 22.2 17.6 19.2 23.7 11.1 24.8 8.3 25.5 6.8 31.1 2.5 31.9 0.9 39.5 5.3 61.5 2.7 69.1 3.1 75.7 4.9 87.3 9.3 107 11.6 119.2 17.6 125 17.5 137 19.9 138 19.2z"
                    ></path>
                    <circle
                      cx="60"
                      cy="35"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(254 569)"
                    className="map_point"
                    data-title="嘉義縣"
                    data-school="3"
                    data-people="13902"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M26.6 95.2l-1.8 1.4-.4-1.1 2.4-3.1.9-2.4 1.1-1.5.6.5.1 1.1-1.4 2-1.5 3.1zm2.2-12.6l-.7-.7 2-3.8.9-.4-.5 1.9-1.7 3zm4.9-9l-.1-4.4.5.4.1 2.9-.5 1.1zm1.4-9.7l-.8 1.6-.5-2 1.3.4zm-1.3-6.1l-.5 1.3L32 57l1.8.8zM4 58.8L.8 60.4 4.6 55l13.9-14.1 10.6-13.5 2.5-.8-9 14.7-4.5 4.5-2.9 3.5-3.8 3.8-3 2.1L4 58.8zM193.5 7.7l6.1.7 6.8 2.6 4.1 1.2 5.1 2.8-.3 5.3-3 5.1 1.2 4.8 2.7 6.5.4 5.9 1.1 3.8 7.6 1.4 25.6.2.9.2-18.5 9.4-8.5 6.8-3.6 6.8-4.2 5-7.7 4.3-7.9 6.1-4.5 5.4-12.3 7.3-5.8.6-6.9-.7-3.6 3.5-.1 5 1.4 4.5 1 6.7-8.4-1-3.7-1.1-5 .6-5.3 2.4h-3.8l-4.5-3.5-1.6-4.6 1.8-14.2-1-7.5-9.9-10.1-1.7-5.1-2.1-4.2-4-2.4-7.2-2.4-8.5.5-15.2 2.8-7.8 3.5-6.1 4.6-4.7 4.8-6.6 5.1-5.8 3.6-2.5 4.9-6.2 2.8-7.2-1.8-8.9-1.3-5.2-2 3.8-.3 3.3-1.4 1.4.5-.9-3.2-2.2 1.6-1.8-.5-2.5-.1v-3.3L33 81l3.7-.3 5.4.1-3.7-10.5-4.1-8 1.9-3.7v-1.9l-3.1-1.5.4-1.8 1.8-2 .9-2.6 4-5.2-8.5.5-.2-8.7L43.1 34l8.3 4.7 6.1.1 3.9-3.2 1.7-4.9 2.1-3 5.3-.8 7-5.7 4.4-2.4 3.8-3.8L102 5.6l7.2-2 12.6-2.3 6.9-.4 5.3 4.6 5 5.9 12.6 4.6 3.7-.5 8.3-2.4 4.7 3.4 2.4 3.9 6.3-.6 11.7-2.4 4-1.3v-3.4l.3-5h.5zm-92.7 30.8L97 42.6l.9 5.6 6.1 2.4 7 4.5 7.3 1.7 6.8-2.7 4.5-.6 3-2.2-2.5-6.8-2.5-4-4.1-2.2-5.6-2.1-7.8.7-9.3 1.6z"
                    ></path>
                    <circle
                      cx="63"
                      cy="53"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(260 634)"
                    className="map_point"
                    data-title="台南市"
                    data-school="7"
                    data-people="21534"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4.8 105.3l-.6 1.2-2.3-2-1.7-4.2.3-1.3h.7l.9 1.2.7 1.9 2.1 2.2-.1 1zm.5-27.6l-2.9 6.8 3.9-13.3.6.1-1.6 6.4zm3.5-12.8l3.5-9.9.5-.1.2 1.9-4.2 8.1zm6.3-22.5l-.4 2.4.6-10.6.5.2-.7 8zm149.5 11.5l-2.3 6.6-10.4 15.1-10 17-6.5 8.4-7.4 7.2-6.5 5.4-6.1 6.4-5.3 7.1-3 5.2-2.4 5.5-6.3 4.4-6.5 2.6-3.5 2.6-4.2 1.5-8.4-1-8.2.9-15.7-2.2-4.8-2.5-3.9-8.6-.2-.5-6.1 3.7-1-6.3-1.8-3.6-3.9-3.9 4.7-3.4 2.7-6.1-1.1-5.2-6.3-.9-1.2 1.8-1.1 3.4-1.8 2.7-3.3-.1-1.3-2.3.5-7-.8-2.4-4.1-1.8-2.3 2.1-1.8 2.9-2.8.7-2.9-2.5-.7-3.5.7-3.4 1.1-2.3 3-1.6 3.7-.5 3.1-1 1-3-1.4-1.5-9.4-2.4v-1.9l7.9-.1-.8-2.6-4.3-3.7-2.8-3.2.7-4 2.5-1.5 2.7-.9 1.4-2.5-.8-3.9-1.4-3.2.9-3 1.4-3.7 2.3-2.6-.5-2.3.5-2.3 3.3-9 1.6-1.7 2.6-.6-3.7-2.5 1.6-4.1 5.2 2 8.9 1.3 7.2 1.8 6.2-2.8 2.5-4.9 5.8-3.6 6.6-5.1 4.7-4.8 6.1-4.6 7.8-3.5 15.2-2.8 8.5-.5 7.2 2.4 4 2.4 2.1 4.2 1.7 5.1 9.9 10.1 1 7.5-1.8 14.2 1.6 4.6 4.5 3.5h3.8l5.3-2.4 5-.6 3.7 1.1 8.4 1z"
                    ></path>
                    <circle
                      cx="76"
                      cy="69"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(296 617)"
                    className="map_point"
                    data-title="高雄市"
                    data-school="6"
                    data-people="28809"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M224.8 11.3L226.7 17.1 223.7 22.1 218.1 24.5 216.7 27.7 217.7 31.7 223.3 35.3 229.6 40.5 232.4 48.1 232.7 50.6 217.1 54.4 210.7 58.5 206.7 62.4 198.9 67.5 198 73.2 200.3 79.2 197.6 84.1 190.2 88.1 189.4 95 191.5 104.9 188.5 118.1 188.4 129.3 184.1 135.2 175.6 142.4 173.3 152.2 174.6 160.3 177.6 164.9 183.8 171.3 185 176.2 181.1 176.7 175.9 175.8 171.6 176 163.1 182.7 157.2 177.2 149.6 168.3 142.4 170.1 135.8 176 122.7 169.4 113.3 173.9 106.9 181 98.9 183.8 81.1 182.7 79.4 187.6 78.1 194.8 75.5 202.7 76 209.7 75.9 219.2 70.2 240.4 70.1 247.5 72.8 255 73.2 263.2 65.1 283.7 62.5 282.6 60.9 282.7 59.1 283.4 56.9 283.4 43.9 273.2 31.8 256.1 44.5 271.5 43 264.1 39.2 256.9 33.9 250.9 24.9 242.5 21.4 238.3 19.8 233.5 22 228.9 23.1 224.8 21.6 219 18.9 213.4 14 205.7 9.4 195.5 8.4 191 8.5 188 10 184.4 10.3 182 9.8 180.8 7.1 178.5 6.6 176.2 3.7 171.3 3 169.3 0.9 155.7 7 152 7.2 152.5 11.1 161.1 15.9 163.6 31.6 165.8 39.8 164.9 48.2 165.9 52.4 164.4 55.9 161.8 62.4 159.2 68.7 154.8 71.1 149.3 74.1 144.1 79.4 137 85.5 130.6 92 125.2 99.4 118 105.9 109.6 115.9 92.6 126.3 77.5 128.6 70.9 127.6 64.2 126.2 59.7 126.3 54.7 129.9 51.2 136.8 51.9 142.6 51.3 154.9 44 159.4 38.6 167.3 32.5 175 28.2 179.2 23.2 182.8 16.4 191.3 9.6 209.8 0.2 214.1 1.2 219.9 3.4 224.1 9.3 224.8 11.3z"
                    ></path>
                    <circle
                      cx="126"
                      cy="136"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(449 627)"
                    className="map_point"
                    data-title="台東縣"
                    data-school="2"
                    data-people="3714"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M221.2 407l-5.5 1.7-7.3-2.9-6.8-4.8-3.9-3.7 1.2-2.7-.1-2.5-1-2.4-1.7-2.1h19.5v1.8l-1.3 3.5-.4 2.9.9 2.5 4.7 3.5 2 2.5-.3 2.7zm-24-177.6l-.1 1.1-3.4-.4-.4-.6-2.7-1-2.8-4.9-1.4-4.3.6-.6 10-.7 1.1.6.8 2-.8 3.9-.9 1.6-.4 1.7.4 1.6zM34.1 343.5l-4.5.3-10.2-4.7-4.6-4.9-3.2-5.9-.6-6.1.1-5.7-3.1-4.4-3.4-1.7-2.7-3.9.5-4.5 2.1-3.9 5.3-3.2 2.5-3.8-4.6-5.6-2.6-5v-5.4L4 268l-2-6.1-1.2-6.5.8-14.7 3-7.5 3.7-6.6 2.4-6.6 5.4-4.5 7.6-1.6 4.8-2.7 9.8-4.1 2-5 .1-5.2 2.8-13.8-5.5-3.6-5.4-1.3-.7-4.9-3.5-6.6 3.9-.5-1.2-4.9-6.2-6.4-3-4.6-1.3-8.1 2.3-9.8 8.5-7.2 4.3-5.9.1-11.2 3-13.2-2.1-9.9.8-6.9 7.4-4 2.7-4.9-2.3-6 .9-5.7 7.8-5.1 4-3.9 6.4-4.1 15.6-3.8 1.2 7.4 5 8.1 7.8 2.8 6.1 2.9 5 4.8 6.3.9 5.7 1.5 9.2 16 11.7 9.7 8.2 1.1 6.5-3.7.9-6.2-.1-6.7 3.1-6.1 4.8-13.2 3.7-7.7 2.4-8.4 2.7-6.6 3.4-5.2 3.6-6.9-.6-6.9-1.7-6.9 3.9-5.4L189.3.7l3.5 1-4.6 26.4L186 33l-7.1 9.4-2.6 4.9-2.1 6.1-1.5 11.4.2 10.2-1 9.7-4.9 9.9-2.1 2.1-4.7 3.5-2.2 2.2-1 2.3-1.7 5.8-4.8 8.4-2.9 13.1-2.1 5.9-10.3 14.8-1.3 2.7-13.9 11.8-3.3 3.8-.4 2.7.6 6.2-.2 2.8-1.2 3.2-2.2 3.5-4.7 6.1-4.7 4.5-16.1 10.7L77 222.6l-2.7 1.4-2.1 2.7-5.2 13.5-2.6 5-8 10-3.3 6-1.9 12.3-4.8 16.8-9.5 16.7-1.4 5.7-1.7 16.1.3 14.7z"
                    ></path>
                    <circle
                      cx="91"
                      cy="138"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(512 358)"
                    className="map_point"
                    data-title="花蓮縣"
                    data-school="3"
                    data-people="2991"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M204.4 14.5L205 15.3 206.6 18.2 206.8 19.3 205.4 21.7 199.6 27.4 193.4 36 188.7 39.7 187.3 41.7 188 43.8 187.1 45.3 180.5 50.4 178 52.8 175.2 58.8 172.3 71 164.9 81.9 163.4 86.3 163.6 91 165.4 96.2 166.9 98.5 168.2 99.5 169 100.9 169.1 104.1 168 107.7 164.3 113.5 163.6 117 163.2 123.4 160 139.7 153.6 156.7 145.6 196.8 140.8 206.6 129.8 270.7 126.3 269.7 115.5 274.9 111.6 280.3 113.3 287.2 113.9 294.1 110.3 301 106.9 306.2 104.2 312.8 101.8 321.2 98.1 328.9 93.3 342.1 90.2 348.2 90.3 354.9 89.4 361.1 82.9 364.8 74.7 363.7 63 354 53.8 338 48.1 336.5 41.8 335.6 36.8 330.8 30.7 327.9 22.9 325.1 17.9 317 16.7 309.6 16.4 307.1 13.6 299.5 7.3 294.3 1.7 290.7 0.7 286.7 2.1 283.5 7.7 281.1 10.7 276.1 8.8 270.3 14.8 266.8 17.9 264 16.2 257.8 17.7 255.1 19.2 251.1 24.4 247.2 31.8 245.6 39.2 245.3 44.5 240.6 45.8 232.1 49.8 226.9 57.4 224.1 61.9 221 63.5 215.9 66.7 203.3 67.9 194.9 67 187.7 65.8 182.3 62.6 178.2 60.5 174.2 66.2 164.8 66.4 161.1 68.9 154.1 72.8 145.6 75.8 136.8 77.4 126.9 78 119.3 76.2 114.8 74.2 111.5 76.5 103.9 81.6 93.2 89.6 82.8 89.9 78.9 88.4 74.4 83.2 71 80.3 67.9 80.1 58 83.8 55.1 90.4 52.9 95.6 50.5 95.4 46.8 94 40.6 96.8 38.6 98 36.5 101.4 34.8 104.5 31 105.8 25.9 108.8 20.9 116.3 16.2 118.7 10 119.1 5.8 123.8 0.9 126.3 3 132.8 6.1 147.8 9.9 156.1 13.1 162.6 13.1 167.5 8.6 169.9 2.6 173.7 1.7 192.2 12.8 203.3 14.5 204.4 14.5z"
                    ></path>
                    <circle
                      cx="110"
                      cy="168"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(417 396)"
                    className="map_point"
                    data-title="南投縣"
                    data-school="1"
                    data-people="23826"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M189 2.6L190.4 8.8 190.6 12.5 185.4 14.9 178.8 17.1 175.1 20 175.3 29.9 178.2 33 183.4 36.4 184.9 40.9 184.6 44.8 176.6 55.2 171.5 65.9 169.2 73.5 171.2 76.8 173 81.3 172.4 88.9 170.8 98.8 167.8 107.6 163.9 116.1 161.4 123.1 161.2 126.8 155.5 136.2 157.6 140.2 160.8 144.3 162 149.7 162.9 156.9 161.7 165.3 158.5 177.9 156.9 183 152.4 186.1 144.8 188.9 140.8 194.1 139.5 202.6 134.2 207.3 126.8 207.6 119.4 209.2 114.2 213.1 112.7 217.1 111.2 219.8 112.9 226 109.8 228.8 103.8 232.3 103.1 230.3 98.9 224.4 93.1 222.2 88.8 221.2 87.9 221 62.3 220.8 54.7 219.4 53.6 215.6 53.2 209.7 50.5 203.2 49.3 198.4 52.3 193.3 52.6 188 47.5 185.2 43.4 184 36.6 181.4 30.5 180.7 27.9 177.2 19.7 178.1 12.9 180.1 7.1 177.6 3.1 172.9 4 167.2 5.9 162 4.9 154.3 5.9 147.1 7.9 142.4 6 135.2 8.4 133.6 10.9 131.3 14.7 129.8 15.7 126.3 10.1 123.4 6.2 122 2.6 118.7 0.5 112.4 1.4 99 2.6 92.4 2.7 86.7 4.3 81.3 6.3 76.7 5.8 72.7 7 69.8 11.3 68.2 11.2 64.6 15.5 64.8 33.4 67.3 37.6 66.7 42.6 61.9 50.2 47.5 53.9 39 60.3 36 70.4 37.4 76.4 37.2 82.2 29.5 85.2 27.6 94.1 34.3 97.6 33.4 100.2 27.9 104.9 24 111.5 24.1 116.5 22.2 124.4 14.6 129.5 11.7 133.9 10.9 142.7 6.2 148.9 6 155.2 5 166.3 0.6 170.9 0.3 175.6 1.3 189 2.6z"
                    ></path>
                    <circle
                      cx="90"
                      cy="116"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(343 785)"
                    className="map_point"
                    data-title="屏東縣"
                    data-school="7"
                    data-people="6663"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7.4 152l2 2.9-.3 1-2.7 1.8-5 4.3-.9-3.3 1.4-2.7 2.8-3.6h2l.7-.4zM134.1 8.7l3.5 6.6.7 4.9 5.4 1.3 5.5 3.6-2.8 13.8-.1 5.2-2 5-9.8 4.1-4.8 2.7-7.6 1.6-5.4 4.5-2.4 6.6-3.7 6.6-3 7.5-.8 14.7 1.2 6.5 2 6.1 1.1 7.1v5.4l2.6 5 4.6 5.6-2.5 3.8-5.3 3.2-2.1 3.9-.5 4.5 2.7 3.9 3.4 1.7 3.1 4.4-.1 5.7.6 6.1 3.2 5.9 4.6 4.9 10.2 4.7 4.5-.3.9 51.6-1.6 7.3-2.5 6-3.4 2.5-4 3.7.1 8.3 1.5 9.2-.4 5.9-4.5-6.8-6.8-5.2-7.8-3.5-8-1.9-.3 6.6-2.3 2.3-3.4-.8-3.7-3.1-.2-2.4 1.3-7-5.5-9.2-.5-2.4.1-2.1.4-3.2v-10.8l.5-3.1 2.5-3.6.5-3-19.9-51.6-1.6-2.9-5.5-6.8-4.4-9.9-2-2.8-8.2-6.5-5.4-3-.9-3.7-.7-1.3-4.4-2.9-10-4.8-4.5-3.1-4.8-4.2-2.6-1.6 8.1-20.5-.4-8.2-2.7-7.5.1-7.1 5.7-21.2.1-9.5-.5-7 2.6-7.9 1.3-7.2 1.7-4.9 17.8 1.1 8-2.8 6.4-7.1 9.4-4.5L88.8 8l6.6-5.9 7.2-1.8 7.6 8.9 5.9 5.5 8.5-6.7 4.3-.2 5.2.9z"
                    ></path>
                    <circle
                      cx="76"
                      cy="100"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(351 605)"
                    className="map_point"
                    data-title="嘉義市"
                    data-school="1"
                    data-people="9906"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3.8 2.5L13.1 0.9 20.9 0.2 26.5 2.3 30.6 4.5 33.1 8.5 35.6 15.3 32.6 17.5 28.1 18.1 21.3 20.8 14 19.1 7 14.6 0.9 12.2 0 6.6z"
                    ></path>
                    <circle
                      cx="18"
                      cy="9"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                  <g
                    transform="translate(682 128)"
                    className="map_point"
                    data-title="基隆市"
                    data-school="2"
                    data-people="10677"
                  >
                    <path
                      fill="#FFF41F"
                      fillRule="nonzero"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M33.8 8L33.5 19.9 33.7 22.9 37.4 26.8 37 29.3 34.5 31.1 30.5 32.6 25.4 32 14.8 27.6 11.8 25.4 8.1 22 5.3 18.5 3.6 15 0.7 11.3 0.1 8.4 3.3 6.2 10.5 3.1 14.9 0.2 16.3 1.1 18.7 3.8 22.6 5.2 33.8 8z"
                    ></path>
                    <circle
                      cx="20"
                      cy="15"
                      r="7.5"
                      fill="#37AAD7"
                      strokeOpacity="0.499"
                      strokeWidth="3"
                    ></circle>
                  </g>
                </g>
              </svg>
            </div>
            <hr />
            <h4>未來期待</h4>
            <p>
              期待每位小朋友都可以成為守護家人的「家庭健康長」，
              填答數越多的學校就越有機會邀請到「千禧健康 OPEN
              小將」出動到校園！跟同學們一起互動！
              一起打擊「代謝症候群」與「肌少症」！歡迎踴躍填答～
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </article>
        </div>

        {/* <div className="family_character_anime chapter_a1">
          <div className="char_item char_open_a"></div>
          <div className="char_item char_open_b"></div>
        </div> */}

        <div className="footer_nav page_nav center">
          <ChapterNavBtn type="next" ClassName="center" Link="/" Text="首頁" />
        </div>
        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
