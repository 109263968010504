import React, { useRef, useMemo, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import getGradeOrClass from "../../getGradeOrClass.js"
import cx from "classnames"

import styForm from "../../form.module.scss"

import sty from "./FormExtendGradeAndClass.module.scss"

const FormExtendGradeAndClass = ({
  school: schoolName,
  gradeID,
  classID,
  county,
  district,
  register,
  onChange,
  inputRegisterConfig,
  dataSchoolList,
  setBasicFromData,
  errors,
}) => {
  const [listOfGrade, setListOfGrade] = useState([])
  const [listOfClass, setListOfClass] = useState() // 老師沒創班級就不用填班級

  const onFail = useCallback(res => {
    console.log(res)
  }, [])

  useEffect(() => {
    setListOfGrade([])
    setListOfClass()

    schoolName &&
      getGradeOrClass(
        {
          shoolID: dataSchoolList.find(
            ({ name, address }) =>
              schoolName === name &&
              RegExp(`(${county})+.*(${district})+`).test(address)
          )?.id,
        },
        { onSuccess: setListOfGrade, onFail }
      )

    return () => {}
  }, [schoolName, county, district])

  useEffect(() => {
    if (gradeID) {
      setListOfClass()
      gradeID &&
        getGradeOrClass(
          { gradeID },
          {
            onSuccess: dataList => {
              // 當老師沒創班級，會為空陣列，就不用選
              setListOfClass(
                dataList.length > 0
                  ? dataList.sort((a, b) => a.id - b.id)
                  : false
              )
            },
            onFail,
          }
        )
    }
    return () => {}
  }, [gradeID])

  useEffect(() => {
    if (listOfGrade && !listOfGrade.find(({ id }) => id == gradeID)) {
      listOfGrade &&
        setBasicFromData({
          gradeID: listOfGrade?.[0]?.id,
        })
    }
    if (listOfClass && !listOfClass.find(({ id }) => id == classID)) {
      listOfClass &&
        setBasicFromData({
          classID: listOfClass?.[0]?.id,
        })
    }

    return () => {}
  }, [listOfGrade, listOfClass, gradeID, classID])

  return (
    <div className="part_2">
      我現在是
      <div
        className={cx(styForm.box__select, { [styForm.hidden]: gradeID })}
        data-placeholder="年級"
      >
        <select
          name="gradeID"
          data-letter="3"
          ref={register(inputRegisterConfig["default"])}
          className={cx(sty.select__grade, { invalid: errors.gradeID })}
          onChange={onChange}
        >
          <option disabled value=""></option>
          {Array.isArray(listOfGrade) &&
            listOfGrade.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </select>
      </div>
      {Array.isArray(listOfClass) && (
        <>
          我在
          <div
            className={cx(styForm.box__select, { [styForm.hidden]: classID })}
            data-placeholder="班級"
          >
            <select
              name="classID"
              ref={register(inputRegisterConfig["default"])}
              className={cx(sty.select__class, { invalid: errors.classID })}
              onChange={onChange}
            >
              <option disabled value=""></option>
              {listOfClass.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  )
}

FormExtendGradeAndClass.propTypes = {}

export default FormExtendGradeAndClass
