import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterPageHeader from "./ChapterPageHeader"
import ChapterNavBtn from "./ChapterNavBtn"
import { Link } from "gatsby"

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: true,
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    })
    // console.log(this.state.isChecked)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap mobile_column">
          <ChapterPageHeader
            ClassName="sty_c"
            // UpperTitle="二十歲以上成人"
            PageTitle="認識標準值"
            // LowerTitle="超出正常值即為腹部肥胖"
          />
          <div className="dialog_group dialog_family dialog_family_result">
            <div className="dialog_item sty_c">
              <div className="dialog_header">
                <p className="main_title">腰圍標準值</p>
              </div>
              <div className="dialog_body">
                <h6>20歲以上成人</h6>
                <p>
                  男性腰圍 ＜ 90公分
                  <br />
                  女性腰圍 ＜ 80公分
                </p>
                <hr />
                <h6>超出正常值即為腹部肥胖</h6>
              </div>
            </div>
            <div className="dialog_item sty_d">
              <div className="dialog_header">
                <p className="main_title">小腿圍標準值</p>
              </div>
              <div className="dialog_body">
                <h6>50歲以上成人</h6>
                <p>
                  男性小腿圍 ≧ 34公分
                  <br />
                  女性小腿圍 ≧ 33公分
                </p>
                <hr />
                <h6>小於標準值可能表示肌肉量不足</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_nav page_nav center">
          <ChapterNavBtn type="next" Link="/D0/" Text="前往任務二" />
        </div>
      </>
    )
  }
}

export default Page
