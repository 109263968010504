import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="chapter_title">
            <ChapterTitle
              type="pri"
              StrokeColor="#8AD01F"
              FontColor="#40C6EA"
              Title="即將揭曉"
            />
            <div className="lighting_bg"></div>
          </div>
        </div>
      </>
    )
  }
}

export default Page
