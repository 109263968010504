import axios from "./axios"
import urlRestAPI from "./urlRestAPI"

export default function updateCustomFieldsUserData(
  data, //{ postID, studentID, ...restDataInACF }
  { onSuccess, onFail }
) {
  // console.log(data)
  axios
    .post(`${urlRestAPI}/dataUpdate`, data)
    .then(({ data }) => {
      onSuccess()
    })
    .catch(error => {
      console.log(error)
      onFail()
    })
}
