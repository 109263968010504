/* eslint-disable */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { globalHistory as history } from "@reach/router"
import React, { useLayoutEffect, useState } from "react"
import PropTypes, { func } from "prop-types"

// import { useStaticQuery, graphql } from "gatsby"

import { gsap, Expo } from "gsap"
import InnerHeight from "ios-inner-height"
import { isMobile } from "react-device-detect"
import Link from "../components/LinkWithTransitionLock"
import window from "global"

var Wrap_InnerHeight = {
  // width: window.innerWidth + "px",
  // height: window.innerHeight + "px",
  // overflowX: "hidden",
}

const Layout = ({ children }) => {
  // const [count, setCount] = useState(0)
  const { location, navigate } = history
  useLayoutEffect(() => {
    var container = document.querySelector(".container")
    function container_style() {
      container.style.height = window.innerHeight + "px"
      container.style.width = window.innerWidth + "px"
    }
    window.addEventListener("resize", function() {
      container_style()
    })
    container_style()

    var chapter_title = document.querySelector(".chapter_title")
    if (chapter_title) {
      gsap.from(chapter_title, {
        opacity: 0,
        scale: 0.9,
        duration: 2,
        translateY: "50%",
        // delay: 0.5,
        ease: Expo.easeOut,
      })
    }

    var page_nav = document.querySelector(".page_nav")
    if (page_nav) {
      gsap.from(page_nav, {
        opacity: 0,
        duration: 1,
        translateY: "25%",
        delay: 0.5,
        ease: Expo.easeOut,
      })
    }

    // const url = typeof window !== "undefined" ?  : ""
    // console.log(location.pathname)

    return () => {
      // unsubscribe event
      // div.removeEventListener("scroll", handleOnScroll)
    }
  }, [])
  return (
    <>
      {/* <main className="container">{children}</main> */}
      <main
        className={`container
        ${location.pathname == "/about" ? "document" : ""} ${
          location.pathname == "/results" ? "document" : ""
        }`}
      >
        {children}
      </main>
      {/* <div className={`background`}> */}
      <div className={`background ${location.pathname == "/" ? "hero" : ""}`}>
        <div className="site_title">
          <h1 className="text">家庭健康長</h1>
        </div>
        <div className="cover">
          <div className="top_ruler cover_ruler"></div>
          <div className="bottom_ruler cover_ruler"></div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
