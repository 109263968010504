import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    document.querySelector(".background").classList.remove("hero")

    var family_character_anime = document.querySelector(
      ".family_character_anime"
    )
    var char_item = document.querySelectorAll(".char_item")
    var chapter_wrap = document.querySelector(".chapter_wrap")

    const active_start = function(event) {
      family_character_anime.classList.add("active_start")
      // console.log("hover")
    }
    const active_end = function(event) {
      family_character_anime.classList.remove("active_start")
      family_character_anime.classList.add("active_end")
      setTimeout(() => {
        family_character_anime.classList.remove("active_end")
      }, 900)
    }

    chapter_wrap.addEventListener("mouseenter", active_start)
    chapter_wrap.addEventListener("mouseleave", active_end)
    chapter_wrap.addEventListener("touchstart", active_start)
    chapter_wrap.addEventListener("touchend", active_end)

    // char_item.forEach(b => b.addEventListener("mouseenter", active_start))
    // char_item.forEach(b => b.addEventListener("mouseleave", active_end))
    // char_item.forEach(b => b.addEventListener("touchstart", active_start))
    // char_item.forEach(b => b.addEventListener("touchend", active_end))

    const message_dialog_item = document.querySelectorAll(
      ".message_dialog_item"
    )
    const start_anime = document.querySelectorAll(".start_anime")
    const page_nav = document.querySelectorAll(".page_nav")

    for (let i = 0; i <= message_dialog_item.length - 1; i++) {
      gsap.from(message_dialog_item[i], {
        opacity: 0,
        scale: 0.8,
        translateY: "50%",
        direction: 0.5,
        ease: Expo.easeOut,
        delay: 1.6 + 0.6 * i,
      })
    }
    gsap.from(start_anime, {
      opacity: 0,
      scale: 0.8,
      translateX: "50%",
      translateY: "50%",
      direction: 2,
      ease: Expo.easeOut,
      delay: 0.3,
    })
    // gsap.from(family_character_anime, {
    //   opacity: 0,
    //   direction: 1,
    //   ease: Expo.easeOut,
    //   delay: 1,
    // })
    gsap.to(start_anime, {
      opacity: 0,
      scale: 0.6,
      translateX: "-20%",
      translateY: "-20%",
      direction: 1.2,
      ease: Expo.easeOut,
      delay: 2,
      zIndex: "-1",
    })

    gsap.from(page_nav, {
      opacity: 0,
      scale: 0.8,
      translateY: "30%",
      direction: 0.5,
      ease: Expo.easeOut,
      delay: 4,
    })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap mobile_column">
          <div className="message_group">
            <div className="start_anime">
              <div className="open_character"></div>
              <div className="lighting_bg"></div>
            </div>
            <div className="message_dialog">
              <p className="message_dialog_item">
                千禧健康 OPEN 小將收到 OPEN
                星球的緊急命令，要去調查地球人的健康狀況！
              </p>
              <p className="message_dialog_item">
                降落到地球後，發現歐爸爸與歐媽媽愛吃甜點與燒烤物，卻不喜歡運動，所以變胖了，再這樣下去要小心有『代謝症候群』的風險！
              </p>
              <div className="message_dialog_item">
                千禧健康OPEN小將也發現歐爺爺與歐奶奶常常毛巾擰不乾、瓶蓋轉不開，雙腿無力、腳步慢，甚至常常會跌倒，是否『肌少症』悄悄找上門了?{" "}
              </div>
              <p className="message_dialog_item last_item">
                千禧健康OPEN小將決定帶著歐妹妹與歐弟弟守護家人的健康，準備好跟他們一起擔任「家庭健康長」了嗎？
              </p>
              <p className="message_dialog_item file_item">
                <a
                  href={require("../document/2022年校園活動介紹.pdf")}
                  className="file"
                  target="_blank"
                >
                  <i className="fa fa-file-text-o"></i>家長請看這邊
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="family_character_anime chapter_a1">
          <div className="char_item char_b_ani"></div>
          <div className="char_item char_c_ani"></div>
          <div className="char_item char_e"></div>
        </div>

        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="next"
            ClassName="center"
            Link="/A2/"
            Text="下一步"
          />
        </div>
        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
