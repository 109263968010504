import axios from "./axios"
import urlRestAPI from "./urlRestAPI"

export default function getGradeOrClass(
  { shoolID, gradeID },
  { onSuccess, onFail }
) {
  const queryString = shoolID ? `school=${shoolID}` : `grade=${gradeID}`

  axios
    .get(`${urlRestAPI}/school?${queryString}`)
    .then(({ data: listData }) => {
      onSuccess(listData)
    })
    .catch(error => {
      console.log(error)
      onFail()
    })
}
