import React, { useRef, useMemo, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import cx from "classnames"

import {
  questionnaireBeforeKey,
  getLocalStorageData,
} from "../handleLocalStorage.js"
import updateCustomFieldsUserData from "../updateCustomFieldsUserData.js"
import sty from "./FormQuestionnaireAfter.module.scss"
import styForm from "../form.module.scss"

import FUC from "../FormUnstatedContainer.js"

const FormQuestionnaireAfter = ({
  isTriggerSubmit,
  setIsTriggerSubmit,
  onSubmitSuccess,
  checkQuizResult,
}) => {
  const isAlreadySendQuestionnaire = useRef(false)
  const [formState, setFormState] = useState({})
  const { handleSubmit, register, errors } = useForm()
  const { formState: formStateFUC } = FUC.useContainer()

  const questionList = useMemo(
    () => ({
      q1_1: { answer: "80", options: ["50", "60", "70", "80"] },
      q1_2: { answer: "90", options: ["60", "70", "80", "90"] },
      q2_1: { answer: "腰圍", options: ["頭圍", "腰圍", "臀圍", "小腿圍"] },
      q2_2: { answer: "小腿圍", options: ["頭圍", "腰圍", "臀圍", "小腿圍"] },
    }),
    []
  )

  const onChange = useCallback(e => {
    e.preventDefault()
    const { value, name } = e.target
    setFormState(prevState => {
      const result = { ...prevState, [name]: value }
      console.log(result)
      checkQuizResult(result)
      return result
    })
  }, [])

  const sendQuestionnaire = useCallback(() => {
    if (!isAlreadySendQuestionnaire.current && formStateFUC.studentID) {
      isAlreadySendQuestionnaire.current = true
      updateCustomFieldsUserData(
        {
          studentID: formStateFUC.studentID,
          ...getLocalStorageData(questionnaireBeforeKey),
          ...formState,
        },
        {
          onSuccess: () => {
            // console.log("問卷送出")
          },
        }
      )
    }
  }, [formState, formStateFUC])

  const onSubmit = useCallback(
    handleSubmit(data => {
      sendQuestionnaire()
      checkQuizResult(data)
      onSubmitSuccess()
    }),
    [sendQuestionnaire]
  )

  useEffect(() => {
    if (Object.keys(errors).length) {
      checkQuizResult(null, errors)
      if (
        !Object.keys(errors).some(key => errors[key].type === "required") &&
        !isAlreadySendQuestionnaire.current
      ) {
        sendQuestionnaire()
      }
    }

    return () => {}
  }, [errors, sendQuestionnaire])

  useEffect(() => {
    if (isTriggerSubmit) {
      onSubmit()
      setIsTriggerSubmit(false)
    }

    return () => {}
  }, [isTriggerSubmit])

  return (
    <form
      className={cx(sty.FormQuestionnaireAfter, "quiz_parent")}
      onSubmit={handleSubmit}
    >
      <div>
        成人女性腰圍應小於
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.after_q1_1,
          })}
          data-placeholder="??"
        >
          <select
            name="after_q1_1"
            ref={register({
              required: true,
              validate: value => value === questionList["q1_1"].answer,
            })}
            className={cx({ invalid: errors.after_q1_1 })}
            onChange={onChange}
          >
            <option value=""></option>
            {questionList["q1_1"].options.map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        公分、成人男性腰圍應小於
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.after_q1_2,
          })}
          data-placeholder="??"
        >
          <select
            name="after_q1_2"
            ref={register({
              required: true,
              validate: value => value === questionList["q1_2"].answer,
            })}
            className={cx({ invalid: errors.after_q1_2 })}
            onChange={onChange}
          >
            <option value=""></option>
            {questionList["q1_2"].options.map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        公分
      </div>

      <div>
        定期量
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.after_q2_1,
          })}
          data-placeholder="??"
        >
          <select
            name="after_q2_1"
            ref={register({
              required: true,
              validate: value => value === questionList["q2_1"].answer,
            })}
            className={cx({ invalid: errors.after_q2_1 })}
            onChange={onChange}
          >
            <option value=""></option>
            {questionList["q2_1"].options.map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        預防三高，遠離代謝症候群、定期量
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.after_q2_2,
          })}
          data-placeholder="??"
        >
          <select
            name="after_q2_2"
            ref={register({
              required: true,
              validate: value => value === questionList["q2_2"].answer,
            })}
            className={cx({ invalid: errors.after_q2_2 })}
            onChange={onChange}
          >
            <option value=""></option>
            {questionList["q2_2"].options.map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        預防肌少症。
      </div>
    </form>
  )
}

FormQuestionnaireAfter.propTypes = {}

export default FormQuestionnaireAfter
