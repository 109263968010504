import React, { useRef, useState, useMemo, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "@reach/router"

import QuestionnaireBefore from "./form/QuestionnaireBefore"

const Q0QuestionnaireBefore = ({}) => {
  const navigate = useNavigate()

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false)

  const handleSubmit = () => {
    setIsTriggerSubmit(true)
  }

  const handleSubmitSuccess = () => {
    navigate("/B0")
  }

  return (
    <>
      <div className="chapter_wrap center">
        <div className="dialog_group dialog_personal dialog_quiz">
          <div className="dialog_item sty_a">
            <div className="dialog_header">
              <p className="main_title">家長熱身賽</p>
              <p className="sub_title">請回答下列題目</p>
            </div>
            <div className="dialog_body">
              <QuestionnaireBefore
                isTriggerSubmit={isTriggerSubmit}
                setIsTriggerSubmit={setIsTriggerSubmit}
                onSubmitSuccess={handleSubmitSuccess}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="footer_nav page_nav center">
        <button className="btn_next" onClick={handleSubmit}>
          <a>下一步</a>
        </button>
      </div>
    </>
  )
}

Q0QuestionnaireBefore.propTypes = {}

export default Q0QuestionnaireBefore
