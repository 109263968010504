/* eslint-disable */
import React, { useRef, useMemo, useState, useCallback, useEffect } from "react"
import PropTypes, { arrayOf } from "prop-types"
import cx from "classnames"

import sty from "./SelectElementarySchool.module.scss"

const SelectElementarySchool = React.forwardRef(
  (
    {
      className,
      county,
      district,
      setBasicFromData,
      dataSchoolList,
      value,
      ...restProps
    },
    ref
  ) => {
    const schoolOptions = useMemo(() => {
      if (county && district) {
        return dataSchoolList.filter(({ address }) => {
          return RegExp(`(${county})+.*(${district})+`).test(address)
        })
      } else {
        return []
      }
    }, [county, district])

    useEffect(() => {
      if (!schoolOptions.find(({ name }) => name === value)) {
        setBasicFromData({
          school: "",
        })
      }
      return () => {}
    }, [schoolOptions, value])

    return (
      <select
        ref={ref}
        // disabled={schoolOptions.length === 0}
        className={cx(className, sty.SelectElementarySchool)}
        value={value}
        {...restProps}
      >
        <option disabled value=""></option>
        {schoolOptions.map(({ id, name }, index) => (
          <option key={name + index} value={name}>
            {name}
          </option>
        ))}
      </select>
    )
  }
)

SelectElementarySchool.propTypes = {
  county: PropTypes.string,
  district: PropTypes.string,
}

export default SelectElementarySchool
