import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import { gsap, Expo } from "gsap"

const ChapterPageHeader = props => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    var chapter_page_header = document.querySelector(".chapter_page_header")
    if (chapter_page_header) {
      // console.log(sty.ChapterPageHeader)
      gsap.from(chapter_page_header, {
        opacity: 0,
        translateX: "-50%",
        translateY: "15%",
        duration: 0.5,
        ease: Expo.easeOut,
        delay: 0.5,
      })
    }
    return () => {
      // unsubscribe event
      // div.removeEventListener("scroll", handleOnScroll)
    }
  }, [])

  return (
    <div className="chapter_page_header">
      {props.UpperTitle ? (
        <h6 className="sub_title lower">{props.UpperTitle}</h6>
      ) : null}
      <h2 className={`page_title ${props.ClassName}`}>{props.PageTitle}</h2>
      {props.LowerTitle ? (
        <h6 className="sub_title upper">{props.LowerTitle}</h6>
      ) : null}
    </div>
  )
}

export default ChapterPageHeader
