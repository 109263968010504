import React, { useRef, useState, useCallback, useEffect } from "react"
import cx from "classnames"
import { gsap, Expo, TweenMax, Power2 } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"
import Link from "../components/LinkWithTransitionLock"

import visual_bg from "../images/a0/main_visual_bg.png"
import visual_0 from "../images/a0/main_visual_0.png"
import visual_1 from "../images/a0/main_visual_1.png"
import visual_2 from "../images/a0/main_visual_2.png"
import visual_3 from "../images/a0/main_visual_3.png"
import visual_4 from "../images/a0/main_visual_4.png"
import visual_5 from "../images/a0/main_visual_5.png"
import visual_6 from "../images/a0/main_visual_6.png"
import visual_7 from "../images/a0/main_visual_7.png"
import visual_8 from "../images/a0/main_visual_8.png"
import visual_9 from "../images/a0/main_visual_9.png"

import sty from "./A0.module.scss"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const visual_item = document.querySelectorAll(".visual_item")

    for (let i = 0; i <= visual_item.length - 1; i++) {
      gsap.from(visual_item[i], {
        opacity: 0,
        scale: 0.95,
        translateY: "-5%",
        duration: 2,
        ease: Expo.easeOut,
        delay: 1.2 + 0.1 * i,
      })
    }

    gsap.to(".hero", {
      opacity: 1,
      duration: 0.5,
      ease: Expo.easeOut,
      // delay: 0.2,
    })
    // document.querySelector(".background").classList.add("hero")

    var background_ruler = gsap.timeline()
    var cover_ruler = document.querySelectorAll(".cover_ruler")
    background_ruler
      .to(cover_ruler, {
        backgroundPosition: "400% 0%",
        duration: 5,
        ease: Power2.easeOut,
      })
      .set(cover_ruler, {
        backgroundPosition: "0% 0%",
      })

    gsap.from(".top_ruler", {
      translateY: "-100%",
      duration: 3,
      ease: Power2.easeOut,
      delay: 0.2,
    })
    gsap.from(".bottom_ruler", {
      translateY: "100%",
      duration: 3,
      ease: Power2.easeOut,
      delay: 0.2,
    })
    gsap.from(".hero_title", {
      opacity: 0,
      scale: 0.8,
      duration: 1,
      ease: Expo.easeOut,
      delay: 2,
    })
    gsap.from(".page_nav", {
      opacity: 0,
      scale: 0.8,
      duration: 1,
      ease: Expo.easeOut,
      delay: 1,
    })
    gsap.from(".home_nav", {
      opacity: 0,
      scale: 0.8,
      duration: 1,
      ease: Expo.easeOut,
      delay: 1,
    })

    const hero = document.querySelector(".hero")
    if (hero) {
      const hero_move = function(e) {
        var w = hero.offsetWidth
        var h = hero.offsetHeight
        var center = { x: w / 2, y: h / 2 }
        var shadowLimit = 200

        var evX = e.clientX
        var evY = e.clientY

        var shadowX = (center.x - evX) / 20
        var shadowY = (center.y - evY) / 20

        shadowX = shadowX > shadowLimit ? shadowLimit : shadowX
        shadowX = shadowX < shadowLimit * -1 ? shadowLimit * -1 : shadowX
        shadowY = shadowY > shadowLimit ? shadowLimit : shadowY
        shadowY = shadowY < shadowLimit * -1 ? shadowLimit * -1 : shadowY

        TweenMax.to(".layer_0", 2, {
          transformPerspective: 500,
          transformOrigin: "center center",
          x: 0.3 * shadowY,
          y: 0.3 * shadowX,
          ease: Expo.easeOut,
        })
        TweenMax.to(".layer_2", 2, {
          transformPerspective: 500,
          transformOrigin: "center center",
          x: 0.3 * shadowX,
          y: 0.3 * shadowY,
          ease: Expo.easeOut,
        })
        TweenMax.to(".layer_3", 2, {
          transformPerspective: 500,
          transformOrigin: "center center",
          x: -0.3 * shadowX,
          y: -0.3 * shadowY,
          ease: Expo.easeOut,
        })
        TweenMax.to(".layer_4", 2, {
          transformPerspective: 500,
          transformOrigin: "center center",
          x: -0.2 * shadowY,
          y: -0.2 * shadowX,
          ease: Expo.easeOut,
        })
      }

      hero.addEventListener("mousemove", hero_move)
      hero.addEventListener("touchstart", hero_move)
    }
    // window.addEventListener("mousemove", hero_move)
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center hero_visual">
          {/* <div className="chapter_title">
            <ChapterTitle
              type="pri"
              StrokeColor="#8AD01F"
              FontColor="#323232"
              Title="即將登場"
            />
          </div> */}

          <div className="home_nav">
            <ul className="nav_list">
              <li className="list_item">
                <Link to="/results">歷年成果</Link>
              </li>
              <li className="list_item">
                <Link to="/about">關於我們</Link>
              </li>
            </ul>
          </div>

          <div className="hero">
            <h2 className="hero_title">
              <span>邀請OPEN！</span>
              <span>元氣校慶開幕式</span>
            </h2>
            <div className="mainvisual_group">
              <div className="visual_item">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_bg})` }}
                ></div>
              </div>
              <div className="visual_item layer_0">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_0})` }}
                ></div>
              </div>
              <div className="visual_item zind_8">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_1})` }}
                ></div>
              </div>
              <div className="visual_item layer_3 zind_7">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_2})` }}
                ></div>
              </div>
              <div className="visual_item layer_2 zind_7">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_3})` }}
                ></div>
              </div>
              <div className="visual_item layer_3 zind_7">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_4})` }}
                ></div>
              </div>
              <div className="visual_item layer_2 zind_7">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_5})` }}
                ></div>
              </div>
              <div className="visual_item layer_4 zind_8">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_6})` }}
                ></div>
              </div>
              <div className="visual_item zind_9">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_7})` }}
                ></div>
              </div>
              <div className="visual_item zind_10">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_8})` }}
                ></div>
              </div>
              <div className="visual_item zind_10">
                <div
                  className="item_inner"
                  style={{ backgroundImage: `url(${visual_9})` }}
                ></div>
              </div>
            </div>
          </div>
          <div className={cx("page_nav", sty.box__btns)}>
            <ChapterNavBtn
              type="pri"
              ClassName=""
              Link="/A1/"
              Text="我是學生"
            />
            <ChapterNavBtn
              type="a"
              ClassName={sty.btn__family}
              Link="http://health-check.1000-love.org.tw"
              Text="我是家長"
            />
          </div>
        </div>

        {/* <div className="footer_nav page_nav between">
          <ChapterNavBtn
            type="previous"
            ClassName=""
            Link="/page/"
            Text="上一步"
          />
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/A1/"
            Text="我要看小腿圍"
          />
          <ChapterNavBtn type="next" ClassName="" Link="/page/" Text="下一步" />
        </div> */}

        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
