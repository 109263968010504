import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Link from "../../components/LinkWithTransitionLock"

const ChapterNavBtn = props => {
  if (props.type == "pri") {
    return (
      <div className={`btn_pri ${props.ClassName}`}>
        <Link to={props.Link}>{props.Text}</Link>
      </div>
    )
  } else if (props.type == "a") {
    return (
      <div className={`btn_pri ${props.ClassName}`}>
        <a href={props.Link} target="_blank" rel="noopener noreferrer">
          {props.Text}
        </a>
      </div>
    )
  } else if (props.type == "previous") {
    return (
      <div className={`btn_previous ${props.ClassName}`}>
        <Link to={props.Link}>{props.Text}</Link>
      </div>
    )
  } else if (props.type == "sec") {
    return (
      <div className={`btn_sec ${props.ClassName}`}>
        <Link to={props.Link}>{props.Text}</Link>
      </div>
    )
  } else if (props.type == "next") {
    return (
      <div className={`btn_next ${props.ClassName}`}>
        <Link to={props.Link}>{props.Text}</Link>
      </div>
    )
  } else if (props.type == "previous") {
    return (
      <div className={`btn_previous ${props.ClassName}`}>
        <Link to={props.Link}>{props.Text}</Link>
      </div>
    )
  }
}

export default ChapterNavBtn
