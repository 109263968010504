import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center ending">
          <div className="chapter_title">
            <article className="ending_article">
              <h3>恭喜任務完成！</h3>
              <p>
                身為優秀的家庭健康長，別忘了持續關心家人的健康，定期幫家人測量「腰圍」與「小腿圍」，提醒他們從數據來檢視自身健康，並且養成健康飲食、規律運動的生活型態！
              </p>
              <h5>讓我們繼續守護家人的健康吧！</h5>
            </article>
          </div>
          <div className="lighting_bg_wrap">
            <div className="lighting_bg"></div>
          </div>
        </div>
        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/E0/"
            Text="上一步"
          />
        </div>
      </>
    )
  }
}

export default Page
