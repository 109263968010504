import React, { useRef, useState, useCallback, useEffect } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"
import Slider from "react-slick"

const settings = {
  centerMode: true,
  // centerPadding: "60px",
  dots: false,
  // infinite: false,
  // arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        // variableWidth: true,
        // centerPadding: "80px",
        slidesToShow: 1,
      },
    },
  ],
}
class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="chapter_page_header">
            <h6 className="sub_title upper">守護家人健康</h6>
            <h2 className="page_title sty_c">漫畫篇</h2>
          </div>
          <div className="chapter_content">
            <div className="comic_slider remove_item_4">
              <Slider {...settings}>
                <Link to="/E2_1" className="comic_item">
                  <img
                    src={require("../images/e2/E2_1-1.png")}
                    alt="控制篇"
                    className="comic_img"
                  />
                  <h5 className="comic_title">控制篇</h5>
                </Link>
                <Link to="/E2_2" className="comic_item">
                  <img
                    src={require("../images/e2/E2_2-1.png")}
                    alt="健康飲食篇"
                    className="comic_img"
                  />
                  <h5 className="comic_title">健康飲食篇</h5>
                </Link>
                <Link to="/E2_3" className="comic_item">
                  <img
                    src={require("../images/e2/E2_3-1.png")}
                    alt="運動篇抱"
                    className="comic_img"
                  />
                  <h5 className="comic_title">運動篇</h5>
                </Link>
                <Link to="/E2_1" className="comic_item">
                  <img
                    src={require("../images/e2/E2_1-1.png")}
                    alt="控制篇"
                    className="comic_img"
                  />
                  <h5 className="comic_title">控制篇</h5>
                </Link>
                <Link to="/E2_2" className="comic_item">
                  <img
                    src={require("../images/e2/E2_2-1.png")}
                    alt="健康飲食篇抱"
                    className="comic_img"
                  />
                  <h5 className="comic_title">健康飲食篇</h5>
                </Link>
                <Link to="/E2_3" className="comic_item">
                  <img
                    src={require("../images/e2/E2_3-1.png")}
                    alt="運動篇抱"
                    className="comic_img"
                  />
                  <h5 className="comic_title">運動篇</h5>
                </Link>
              </Slider>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav between">
          <ChapterNavBtn
            type="previous"
            ClassName=""
            Link="/E0/"
            Text="守護健康"
          />
          <ChapterNavBtn type="next" ClassName="" Link="/E1/" Text="文字篇" />
        </div>
      </>
    )
  }
}

export default Page
