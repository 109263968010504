export const storageKey = "ui"
export const questionnaireBeforeKey = "QuestionnaireBefore"

export function getLocalStorageData(key = storageKey) {
  return JSON.parse(localStorage.getItem(key))
}

export function setLocalStorageData(data, key = storageKey) {
  localStorage.setItem(key, JSON.stringify(data))
}
