import React, { useRef, useState, useMemo, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import cx from "classnames"

import sty from "./QuestionnaireBefore.module.scss"
import styForm from "../form.module.scss"
import {
  setLocalStorageData,
  questionnaireBeforeKey,
} from "../handleLocalStorage.js"

const QuestionnaireBefore = ({
  isTriggerSubmit,
  setIsTriggerSubmit,
  onSubmitSuccess,
}) => {
  const [formState, setFormState] = useState({})
  const { handleSubmit, register, errors } = useForm()
  const options = useMemo(
    () => ({
      q1_1: ["50", "60", "70", "80", "我不知道"],
      q1_2: ["60", "70", "80", "90", "我不知道"],
      q2_1: ["頭圍", "腰圍", "臀圍", "小腿圍", "我不知道"],
      q2_2: ["頭圍", "腰圍", "臀圍", "小腿圍", "我不知道"],
    }),
    []
  )
  const onChange = useCallback(e => {
    e.preventDefault()
    const { value, name } = e.target
    setFormState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const onSubmit = handleSubmit(data => {
    // console.log(data)
    setLocalStorageData(data, questionnaireBeforeKey)
    onSubmitSuccess()
  })

  useEffect(() => {
    if (isTriggerSubmit) {
      onSubmit()
      setIsTriggerSubmit(false)
    }

    return () => {}
  }, [isTriggerSubmit])

  return (
    <form className={`form ${sty.QuestionnaireBefore}`} onSubmit={handleSubmit}>
      <h6>請選出正確的成人腰圍標準。</h6>
      <div className="quiz_parent">
        成人女性腰圍應小於
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.before_q1_1,
          })}
          data-placeholder="00"
        >
          <select
            name="before_q1_1"
            ref={register({
              required: true,
            })}
            data-letter="4"
            className={cx({ invalid: errors.before_q1_1 })}
            onChange={onChange}
          >
            <option value=""></option>
            {options["q1_1"].map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        公分，男性腰圍應小於
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.before_q1_2,
          })}
          data-placeholder="00"
        >
          <select
            name="before_q1_2"
            ref={register({
              required: true,
            })}
            data-letter="4"
            className={cx({ invalid: errors.before_q1_2 })}
            onChange={onChange}
          >
            <option value=""></option>
            {options["q1_2"].map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        公分
      </div>
      <div className="quiz_parent">
        定期量
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.before_q2_1,
          })}
          data-placeholder="00"
        >
          <select
            name="before_q2_1"
            ref={register({
              required: true,
            })}
            data-letter="4"
            className={cx({ invalid: errors.before_q2_1 })}
            onChange={onChange}
          >
            <option value=""></option>
            {options["q2_1"].map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        預防三高，遠離代謝症候群；定期量
        <div
          className={cx(styForm.box__select, {
            [styForm.hidden]: formState?.before_q2_2,
          })}
          data-placeholder="00"
        >
          <select
            name="before_q2_2"
            ref={register({
              required: true,
            })}
            data-letter="4"
            className={cx({ invalid: errors.before_q2_2 })}
            onChange={onChange}
          >
            <option value=""></option>
            {options["q2_2"].map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        預防肌少症。
      </div>
    </form>
  )
}

QuestionnaireBefore.propTypes = {}

export default QuestionnaireBefore
