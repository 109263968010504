import axios from "./axios"
import { setLocalStorageData } from "./handleLocalStorage.js"
import urlRestAPI from "./urlRestAPI"

export default function postHealthData(data, { onSuccess, onFail }) {
  setLocalStorageData(data)

  axios
    .post(`${urlRestAPI}/data`, data)
    .then(({ data }) => {
      onSuccess()
    })
    .catch(error => {
      console.log(error)
      onFail()
    })
}
