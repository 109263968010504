/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import sS from "../selection-settings.js"

import styForm from "../../form.module.scss"
import sty from "./LiFamilyMember.module.scss"

import { gsap, Expo } from "gsap/gsap-core"

const inputRegisterConfig = {
  default: {
    required: true,
  },
}

const LiFamilyMember = ({
  data,
  index,
  errors,
  register,
  unregister,
  onChangeInput,
  removeCardByIndex,
}) => {
  const renderSelectOptions = keyName => (
    <>
      <option
        className={styForm.option__placeholder}
        value=""
        disabled
      ></option>
      {sS[keyName]["list"].map((value, index) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </>
  )

  const renderRangeOfOptions = keyName => {
    return (
      <>
        <option
          className={styForm.option__placeholder}
          value=""
          disabled
        ></option>
        {[...Array(sS[keyName].max - sS[keyName].min)].map((nulll, index) => (
          <option key={`keyName${index}`} value={index + sS[keyName].min}>
            {index + sS[keyName].min}
          </option>
        ))}
      </>
    )
  }

  useEffect(() => {
    if (data?.age < 50) {
      unregister(`family[${index}].calf`)
    }
    return () => {}
  }, [data])

  // gsap.from(document.querySelector(".dialog_item.sty_a"), {
  //   opacity: 0,
  //   direction: 1,
  //   ease: Expo.easeOut,
  // })

  return (
    <li
      className={`dialog_item active sty_a ${cx(sty.LiFamilyMember, {
        invalid: errors,
      })}`}
    >
      {/* <div className=""> */}
      <div className="dialog_header">
        <p className="main_title">我的{data?.title || "家人"}</p>
        {errors && <p className="sub_title">請完整填寫</p>}
        <button
          type="button"
          className="remove_btn"
          onClick={() => removeCardByIndex(index)}
        >
          ×
        </button>
      </div>
      <div className="dialog_body">
        <div className="part_1">
          我的
          <div
            className={cx(styForm.box__select, {
              [styForm.hidden]: data?.title,
            })}
            data-placeholder="爺爺"
          >
            <select
              name={`family[${index}].title`}
              className={cx({ invalid: errors?.title })}
              ref={register(inputRegisterConfig["default"])}
              data-letter="2"
              data-index={index}
              data-name="title"
              defaultValue={data?.title || ""}
              onChange={onChangeInput}
            >
              {renderSelectOptions("title")}
            </select>
          </div>
          今年
          <div
            className={cx(styForm.box__select, { [styForm.hidden]: data?.age })}
            data-placeholder="60"
          >
            <select
              name={`family[${index}].age`}
              className={cx({ invalid: errors?.age })}
              ref={register(inputRegisterConfig["default"])}
              data-letter="1"
              data-index={index}
              data-name="age"
              defaultValue={data?.age || ""}
              onChange={onChangeInput}
            >
              {renderRangeOfOptions("age")}
            </select>
          </div>
          歲，
        </div>
        {/* <br /> */}
        <div className="part_2">
          腰圍
          <div
            className={cx(styForm.box__select, {
              [styForm.hidden]: data?.waist,
            })}
            data-placeholder="00"
          >
            <select
              name={`family[${index}].waist`}
              className={cx({ invalid: errors?.waist })}
              ref={register(inputRegisterConfig["default"])}
              data-letter="1"
              data-index={index}
              data-name="waist"
              defaultValue={data?.waist || ""}
              onChange={onChangeInput}
            >
              {renderRangeOfOptions("waist")}
            </select>
          </div>
          公分
          {data?.age >= 50 && (
            <>
              ，小腿圍
              <div
                className={cx(styForm.box__select, {
                  [styForm.hidden]: data?.calf,
                })}
                data-placeholder="00"
              >
                <select
                  name={`family[${index}].calf`}
                  className={cx({ invalid: errors?.calf })}
                  ref={register(inputRegisterConfig["default"])}
                  data-letter="1"
                  data-index={index}
                  data-name="calf"
                  defaultValue={data?.calf || ""}
                  onChange={onChangeInput}
                >
                  {renderRangeOfOptions("calf")}
                </select>
              </div>
              公分
            </>
          )}
        </div>
        <div className="part_3">
          ，以
          <div
            className={cx(styForm.box__select, {
              [styForm.hidden]: data?.gender,
            })}
            data-placeholder="男性"
          >
            <select
              name={`family[${index}].gender`}
              className={cx({ invalid: errors?.gender })}
              ref={register(inputRegisterConfig["default"])}
              data-letter="2"
              data-index={index}
              data-name="gender"
              defaultValue={data?.gender || ""}
              // value={data?.gender}
              onChange={onChangeInput}
            >
              {renderSelectOptions("gender")}
            </select>
          </div>
          標準分析。
        </div>
        <div className="agreement_box">
          <label
            htmlFor={`confirm-${index}`}
            className={cx({ "label__confirm--invalid": errors?.confirm })}
          >
            <input
              id={`confirm-${index}`}
              name={`family[${index}].confirm`}
              type="checkbox"
              className="agreement_input"
              defaultChecked={!!data?.confirm}
              ref={register(inputRegisterConfig["default"])}
            />{" "}
            <div className="agreement_note">
              {data?.title}本人同意上述搜集個人資料告知事項
            </div>
          </label>
        </div>
      </div>
      {/* </div> */}
    </li>
  )
}

LiFamilyMember.propTypes = {}

export default LiFamilyMember
