import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterPageHeader from "./ChapterPageHeader"
import ChapterNavBtn from "./ChapterNavBtn"
import Slider from "react-slick"

const settings = {
  centerMode: true,
  // centerPadding: "60px",
  dots: false,
  // infinite: false,
  // arrows: false,
  speed: 500,
  slidesToShow: 3,
  // slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
  ],
}

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var comic_slider = document.querySelector(".comic_slider")
    var comic_slider_inner = comic_slider.querySelector(".slick-list")

    gsap.from(comic_slider, {
      opacity: 0,
      ease: Expo.easeOut,
      duration: 1,
      delay: 0.3,
    })

    gsap.from(comic_slider_inner, {
      opacity: 0,
      translateX: "30%",
      ease: Expo.easeOut,
      duration: 2,
      delay: 0.5,
    })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <ChapterPageHeader
            ClassName="sty_b"
            UpperTitle="五十歲以上成人"
            PageTitle="腿圍三三四，行動自由適"
            LowerTitle="小於標準值可能表示肌肉量不足"
          />
          <div className="chapter_content">
            <div className="comic_slider remove_item_4">
              <Slider {...settings}>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_2-1.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">除去小腿覆蓋衣物</div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_2-2.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">
                    採正坐姿，雙腳自然踩地，小腿與大腿呈 90 度
                  </div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_2-3.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">
                    以皮尺繞過小腿最寬位置，皮尺與地面保持水平，緊貼而不擠壓皮膚
                  </div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_2-4.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">量取小腿圍公分數</div>
                </div>
                <div className="comic_item hide">
                  <img
                    src={require("../images/b1_1/b1_2-4.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/B1_1/"
            Text="我要看腰圍"
          />
        </div>

        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
