import React, { useRef, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "@reach/router"

import FUC from "../form/FormUnstatedContainer.js"

import ChapterNavBtn from "../ChapterNavBtn"
import sty from "./C5ResultOfFamily.module.scss"

// import Slider from "react-slick"
// const settings = {
//   centerMode: true,
//   // centerPadding: "60px",
//   dots: false,
//   // infinite: false,
//   // arrows: false,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 768,
//       settings: {
//         // centerPadding: "80px",
//         slidesToShow: 1,
//       },
//     },
//   ],
// }

const C5ResultOfFamily = ({ children }) => {
  const navigate = useNavigate()
  const {
    formState: { school, family },
  } = FUC.useContainer()

  // prevent user go this page directly by url, but not filled the form yet
  if (!school || !family[0]?.title) {
    navigate("/C1")
  }

  return (
    <>
      <div className="chapter_wrap mobile_column">
        <div className="dialog_group dialog_family dialog_family_result">
          {/* <Slider {...settings}> */}
          {/* <div className={`aaa`[sty.C5ResultOfFamily]}> */}
          {family.map(({ title, gender, age, waist, calf }, index) => {
            function family_result() {
              var $waist_state, $calf_state
              var $gender = gender
              if (age >= 50) {
                if ($gender == "女性") {
                  if (calf < 33) {
                    $calf_state = "over"
                  } else {
                    $calf_state = "save"
                  }
                } else {
                  if (calf < 34) {
                    $calf_state = "over"
                  } else {
                    $calf_state = "save"
                  }
                }
                if ($gender == "女性") {
                  if (waist >= 80) {
                    $waist_state = "over"
                  } else {
                    $waist_state = "save"
                  }
                } else {
                  if (waist >= 90) {
                    $waist_state = "over"
                  } else {
                    $waist_state = "save"
                  }
                }
              } else {
                if ($gender == "女性") {
                  if (waist >= 80) {
                    $waist_state = "over"
                  } else {
                    $waist_state = "save"
                  }
                } else {
                  if (waist >= 90) {
                    $waist_state = "over"
                  } else {
                    $waist_state = "save"
                  }
                }
              }
              return [$waist_state, $calf_state]
            }

            var text = family_result()
            var waist_s = text[0]
            var calf_s = text[1]

            return (
              <div className="dialog_item sty_a" key={title + index}>
                <div className="dialog_header">
                  <div className="main_title">{title}的測量結果</div>
                  <div className="sub_title">
                    {gender} {age} 歲
                  </div>
                </div>
                <div className="dialog_body">
                  <ul className="result_list">
                    <li
                      className={`result_item waist ${
                        waist_s == "save" ? "save" : "over"
                      }`}
                    >
                      <p className="statu">
                        {waist_s == "save" ? "腰圍正常" : "腰圍不合格"}
                      </p>
                      <p className="num">{waist}公分</p>
                    </li>
                    {age >= 50 ? (
                      <li
                        className={`result_item calf ${
                          calf_s == "save" ? "save" : "over"
                        }`}
                      >
                        <p className="statu">
                          {calf_s == "save" ? "小腿圍正常" : "小腿圍不合格"}
                        </p>
                        <p className="num">{calf}公分</p>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            )
          })}
          {/* </div> */}
          {/* </Slider> */}
        </div>

        {/* <div className="page_nav center">
          <ChapterNavBtn
            type="pri"
            ClassName=""
            Link="/C6/"
            Text="認識標準值"
          />
        </div> */}
      </div>
      <div className="footer_nav page_nav center">
        <ChapterNavBtn
          type="sec"
          ClassName="center"
          Link="/C6/"
          Text="認識標準值"
        />
      </div>
      {/* 
      <div className="footer_nav page_nav center">
        <ChapterNavBtn type="next" Link="/D0/" Text="任務二開始" />
      </div> */}
    </>
  )
}

C5ResultOfFamily.propTypes = {}

export default C5ResultOfFamily
