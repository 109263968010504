import React from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="chapter_title">
            <ChapterTitle
              type="pri"
              StrokeColor="#FFF41F"
              FontColor="#F03388"
              Title="守護健康"
            />
          </div>
          <div className="page_nav center">
            <ChapterNavBtn type="pri" ClassName="m_right_l" Link="/E1/" Text="文字篇" />
            <ChapterNavBtn type="pri" ClassName="" Link="/E2/" Text="漫畫篇" />
            {/* <br></br>
            <div className={`btn_sub`}>
              <a href="https://forms.gle/369pA3pv8pAEvVHs6" target="_blank">
                進階任務：臺北市預防慢性病知識大挑戰
              </a>
              <div className="tooltip">
                <p>感謝臺北市衛生局大力支持，臺北市全體國小皆參與本活動，臺北市的小朋友別忘了還有進階任務喔！</p>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/E3/"
            Text="完成任務"
          />
        </div>
      </>
    )
  }
}

export default Page
