/* eslint-disable */
import React, { useRef, useState, useMemo, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useForm, Controller } from "react-hook-form"

import FUC from "../FormUnstatedContainer.js"
import TwCitySelector from "./tw-city-selector/tw-city-selector.js"
import SelectElementarySchool from "../SelectElementarySchool"
import FormExtendGradeAndClass from "./FormExtendGradeAndClass"
import dataSchool from "../SelectElementarySchool/school.json"

import sty from "./FormPersonalInfo.module.scss"
import styForm from "../form.module.scss"

const inputRegisterConfig = {
  default: {
    required: true,
  },
  studentID: {},
}

const FormPersonalInfo = ({
  isTriggerSubmit,
  setIsTriggerSubmit,
  onSubmitSuccess,
}) => {
  const dataSchoolList = useMemo(() => JSON.parse(dataSchool), [])
  const { handleSubmit, register, errors } = useForm()

  const {
    formState,
    setBasicFromData,
    setBasicFromDataByInputOnChangeEvent,
  } = FUC.useContainer()

  // only update when form validation pass
  const {
    name,
    county,
    district,
    school,
    gradeID,
    classID,
    studentID,
    tall,
    weight,
  } = formState

  const [isNameOrStudentIDFilled, setIsNameOrStudentIDFilled] = useState(
    !!(name || studentID)
  )

  useEffect(() => {
    if (name || studentID) {
      setIsNameOrStudentIDFilled(true)
    } else {
      setIsNameOrStudentIDFilled(false)
    }
    return () => {}
  }, [name, studentID])

  useEffect(() => {
    if (isTriggerSubmit) {
      onSubmit()
      setIsTriggerSubmit(false)
    }

    return () => {}
  }, [isTriggerSubmit])

  const onSubmit = handleSubmit(data => {
    setBasicFromData(data)
    onSubmitSuccess()
  })

  const initCitySelector = () => {
    // eslint-disable-next-line no-new

    new TwCitySelector({
      el: "#form-personal",
      elCounty: "#input-county",
      selectedCounty: county,
      elDistrict: "#input-district",
      selectedDistrict: district,
      standardWords: true,
    })
    setTimeout(() => {
      const domCounty = document.getElementById("input-county")
      const domDistrict = document.getElementById("input-district")

      domCounty.childNodes[0].innerText = ""
      domDistrict.childNodes[0].innerText = ""
    }, 0)
  }

  // clean default option
  useEffect(() => {
    setTimeout(() => {
      const domDistrict = document.getElementById("input-district")
      try {
        domDistrict.childNodes[0].innerText = ""
      } catch (error) {}
    }, 0)
    return () => {}
  }, [county])

  useEffect(() => {
    initCitySelector()
    return () => {}
  }, [])

  return (
    <form
      id="form-personal"
      className={cx(styForm.form__default, sty.FormPersonalInfo, {
        invalid: errors,
      })}
      onSubmit={onSubmit}
    >
      <div className="part_1">
        我就讀在
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: county })}
          data-placeholder="臺北市"
        >
          <select
            id="input-county"
            name="county"
            defaultValue={county}
            data-value={county}
            ref={register(inputRegisterConfig["default"])}
            className={cx({ invalid: errors.county })}
            onChange={setBasicFromDataByInputOnChangeEvent}
          ></select>
        </div>
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: district })}
          data-placeholder="大安區"
        >
          <select
            id="input-district"
            name="district"
            data-letter="3"
            data-value={district}
            defaultValue={district}
            ref={register(inputRegisterConfig["default"])}
            className={cx({ invalid: errors.district })}
            onChange={setBasicFromDataByInputOnChangeEvent}
          ></select>
        </div>
        的
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: school })}
          data-placeholder="OPEN國民小學"
        >
          <SelectElementarySchool
            county={county}
            district={district}
            name="school"
            value={school}
            data-letter="9"
            ref={register(inputRegisterConfig["default"])}
            className={cx({ invalid: errors.school })}
            setBasicFromData={setBasicFromData}
            onChange={setBasicFromDataByInputOnChangeEvent}
            dataSchoolList={dataSchoolList}
          />
        </div>
        。
      </div>
      <FormExtendGradeAndClass
        school={school}
        gradeID={gradeID}
        county={county}
        district={district}
        classID={classID}
        register={register}
        inputRegisterConfig={inputRegisterConfig}
        onChange={setBasicFromDataByInputOnChangeEvent}
        errors={errors}
        dataSchoolList={dataSchoolList}
        setBasicFromData={setBasicFromData}
      />
      <div className="part_3">
        我的學號是
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: studentID })}
          data-placeholder="甲012345678"
        >
          <input
            name="studentID"
            className={cx({ invalid: errors.studentID })}
            defaultValue={studentID}
            data-letter="7"
            type="text"
            ref={register({
              required: !isNameOrStudentIDFilled,
            })}
            defaultValue={studentID}
            onChange={setBasicFromDataByInputOnChangeEvent}
          />
        </div>
        ，姓名是
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: name })}
          data-placeholder="王小小"
        >
          <input
            name="name"
            className={cx({ invalid: errors.name })}
            defaultValue={name}
            type="text"
            data-letter="4"
            ref={register({
              required: !isNameOrStudentIDFilled,
            })}
            defaultValue={name}
            onChange={setBasicFromDataByInputOnChangeEvent}
          />
        </div>
        。
      </div>{" "}
      <div className="part_4">
        我的身高是
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: tall })}
          data-placeholder="000"
        >
          <input
            name="tall"
            className={cx({ invalid: errors.tall })}
            defaultValue={tall}
            type="number"
            data-letter="2"
            ref={register(inputRegisterConfig["default"])}
            defaultValue={tall}
            onChange={setBasicFromDataByInputOnChangeEvent}
            min="100"
            max="200"
          />
        </div>
        公分，體重是
        <div
          className={cx(styForm.box__select, { [styForm.hidden]: weight })}
          data-placeholder="000"
        >
          <input
            name="weight"
            className={cx({ invalid: errors.weight })}
            data-letter="2"
            defaultValue={weight}
            type="number"
            ref={register(inputRegisterConfig["default"])}
            defaultValue={weight}
            onChange={setBasicFromDataByInputOnChangeEvent}
          />
        </div>
        公斤。
      </div>
    </form>
  )
}

FormPersonalInfo.defaultProps = {
  onSubmitSuccess: () => {},
}

FormPersonalInfo.propTypes = {
  isTriggerSubmit: PropTypes.bool,
  setIsTriggerSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
}

export default FormPersonalInfo
