import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="chapter_title">
            <ChapterTitle
              type="pri"
              StrokeColor="#8AD01F"
              FontColor="#323232"
              Title="健康測量"
            />
          </div>
          <div className="page_nav center">
            <ChapterNavBtn
              type="pri"
              ClassName=""
              Link="/B1/"
              Text="學習測量"
            />
          </div>
        </div>

        {/* <div className="footer_nav page_nav between">
          <ChapterNavBtn
            type="previous"
            ClassName=""
            Link="/page/"
            Text="上一步"
          />
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/page/"
            Text="我要看小腿圍"
          />
          <ChapterNavBtn type="next" ClassName="" Link="/page/" Text="下一步" />
        </div> */}

        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
