import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="dialog_group dialog_personal dialog_mission_starter">
            <div className="dialog_item sty_a">
              <div className="dialog_header">
                <p className="main_title">
                  請跟著千禧健康 OPEN 小將完成兩項任務！
                </p>
              </div>
              <div className="dialog_body">
                <div className="mission_item">
                  <h5 className="mission_title">任務一</h5>請找
                  <strong>兩位 20 歲以上的家人</strong>
                  ，使用「家庭健康尺」幫他們測量
                  <strong className="waist">「腰圍」</strong>或
                  <strong className="calf">「小腿圍」</strong>，將數值記錄下來。
                </div>
                <div className="mission_item">
                  <h5 className="mission_title">任務二</h5>
                  守護家人健康，與家人一起<strong>回答挑戰題</strong>。
                </div>
                <hr />
                <p>
                  只要全班都完成所有任務，就可以獲得OPEN小將限量小禮！快跟同學們一起成為優秀的家庭健康長吧！
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="sec"
            ClassName="center"
            Link="/A3/"
            Text="任務一開始"
          />
        </div>
      </>
    )
  }
}

export default Page
