import React, { useState, useCallback, useEffect } from "react"
import loadable from "@loadable/component"

import About from "../about.js"
import Results from "../results.js"

import A_ComingSoon from "../A_ComingSoon.js"
import A0 from "../A0.js"
import A1 from "../A1.js"
import A2 from "../A2.js"
import A3 from "../A3.js"
// import Q0 from "../Q0.js"

import B0 from "../B0.js"
import B1 from "../B1.js"
import B1_1 from "../B1_1.js"
import B1_2 from "../B1_2.js"

import C0 from "../C0.js"
import C1 from "../C1PersonalInfoPage"
import C3 from "../C3FamilyMemberListPage"
import C4 from "../C4"
import C5 from "../C5ResultOfFamily"
import C6 from "../C6"

import D0 from "../D0.js"
import D1 from "../D1.js"

import E0 from "../E0.js"
import E1 from "../E1.js"
import E2 from "../E2.js"
import E2_1 from "../E2_1.js"
import E2_2 from "../E2_2.js"
import E2_3 from "../E2_3.js"
import E3 from "../E3.js"

// import Page from "../../components/page.js"

// const B0 = loadable(() => import(/* webpackPreload: true */ "../B0.js"))
// const Page = loadable(() =>
//   import(/* webpackPreload: true */ "../../components/page.js")
// )

// import C1PersonalInfoPage from "../../components/C1PersonalInfoPage"
// import C3FamilyMemberListPage from "../../components/C3FamilyMemberListPage"
// import C5ResultOfFamily from "../../components/C5ResultOfFamily"

// const Home = loadable(() =>
//   import(/* webpackPreload: true */ "../../components/home.js")
// )
// const Page = loadable(() =>
//   import(/* webpackPreload: true */ "../../components/page.js")
// )
export default [
  // { path: "/", Component: A_ComingSoon },
  { path: "/", Component: A0 },
  { path: "/about", Component: About },
  { path: "/results", Component: Results },
  { path: "/A1", Component: A1 },
  { path: "/A2", Component: A2 },
  { path: "/A3", Component: A3 },
  // { path: "/page", Component: Page },
  // { path: "/Q0", Component: Q0 },

  { path: "/B0", Component: B0 },
  { path: "/B1", Component: B1 },
  { path: "/B1_1", Component: B1_1 },
  { path: "/B1_2", Component: B1_2 },

  { path: "/C0", Component: C0 },
  { path: "/C1", Component: C1 },
  { path: "/C3", Component: C3 },
  { path: "/waiting", Component: C4 },
  { path: "/C5", Component: C5 },
  { path: "/C6", Component: C6 },

  { path: "/D0", Component: D0 },
  { path: "/D1", Component: D1 },

  { path: "/E0", Component: E0 },
  { path: "/E1", Component: E1 },
  { path: "/E2", Component: E2 },
  { path: "/E2_1", Component: E2_1 },
  { path: "/E2_2", Component: E2_2 },
  { path: "/E2_3", Component: E2_3 },
  { path: "/E3", Component: E3 },
  // { path: "/exhibition", Component: ExhibitionTable },
]
