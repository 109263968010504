import React, { useRef, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "@reach/router"

import FormPersonalInfo from "../form/FormPersonalInfo"
import FormListOfFamily from "../form/FormListOfFamily"
import FUC from "../form/FormUnstatedContainer.js"

import sty from "./C1PersonalInfoPage.module.scss"

import { getLocalStorageData } from "../form/handleLocalStorage.js"

const C1PersonalInfoPage = ({ children }) => {
  const navigate = useNavigate()

  const [isMount, setIsMount] = useState(false)

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false)

  const handleSubmit = () => {
    setIsTriggerSubmit(true)
  }

  const { setBasicFromData } = FUC.useContainer()

  useEffect(() => {
    setBasicFromData(getLocalStorageData())
    setIsMount(true)
    return () => {}
  }, [])

  const handleSubmitSuccess = () => {
    navigate("/C3")
  }

  return (
    <>
      <div className="chapter_wrap center">
        <div className="dialog_group dialog_personal">
          <div className="dialog_item sty_a">
            <div className="dialog_header">
              <p className="main_title">完成基本資料填寫</p>
              <p className="sub_title">
                確實填寫才能幫班級累積積分與獲得贈品喔！
              </p>
            </div>
            <div className="dialog_body">
              <div className={sty.C1PersonalInfoPage}>
                {isMount && (
                  <FormPersonalInfo
                    isTriggerSubmit={isTriggerSubmit}
                    setIsTriggerSubmit={setIsTriggerSubmit}
                    onSubmitSuccess={handleSubmitSuccess}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_nav page_nav center">
        {isMount && (
          <button className="btn_next" onClick={handleSubmit}>
            <a>下一步</a>
          </button>
        )}
      </div>
    </>

    // <div className={sty.C1PersonalInfoPage}>
    //   <FormPersonalInfo
    //     isTriggerSubmit={isTriggerSubmit}
    //     setIsTriggerSubmit={setIsTriggerSubmit}
    //     onSubmitSuccess={handleSubmitSuccess}
    //   />

    //   <button onClick={handleSubmit}>下一步</button>
    // </div>
  )
}

C1PersonalInfoPage.propTypes = {}

export default C1PersonalInfoPage
