import axios from "axios"

axios.defaults.validateStatus = function(status) {
  return status >= 200 && status < 400
}
axios.defaults.headers.common["Allow-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.get.Pragma = "no-cache"

export default axios
