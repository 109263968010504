import React, { useRef, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "@reach/router"

import FormListOfFamily from "../form/FormListOfFamily"

import ChapterNavBtn from "../ChapterNavBtn"
import sty from "./C3FamilyMemberListPage.module.scss"
import gsap from "gsap/gsap-core"

const C3FamilyMemberListPage = ({ children }) => {
  const navigate = useNavigate()

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false)

  const handleSubmit = () => {
    setIsTriggerSubmit(true)
  }

  const handleSubmitSuccess = () => {
    navigate("./waiting")
  }
  // ;<div className="chapter_wrap center">
  //   <div className="chapter_title">
  //     <ChapterTitle
  //       type="pri"
  //       StrokeColor="#8AD01F"
  //       FontColor="#40C6EA"
  //       Title="即將揭曉"
  //     />
  //   </div>
  // </div>

  const handlePostSuccess = () => {
    navigate("/C5", { replace: true })
  }

  const handlePostFail = () => {
    alert("發生錯誤，請稍後再試！")
    navigate("/C1")
  }

  useEffect(() => {
    // gsap.set(document.querySelector(".dialog_family"), {
    //   overflow: "hidden",
    //   delay: 0.5,
    // })
    return () => {}
  }, [])

  return (
    <>
      <div className="chapter_wrap mobile_column chapter_family">
        <div className="dialog_group dialog_family">
          {/* <div className={sty.C3FamilyMemberListPage}> */}
          <div className="dialog_note">
            <p>
              此活動是為了培養家庭成員養成健康促進習慣，以不記名方式進行公衛用途之量測數據統計分析，敬請放心填答！
            </p>
          </div>
          <FormListOfFamily
            isTriggerSubmit={isTriggerSubmit}
            setIsTriggerSubmit={setIsTriggerSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            onPostSuccess={handlePostSuccess}
            onPostFail={handlePostFail}
          />
          {/* </div> */}
        </div>
      </div>

      <div className="footer_nav page_nav between">
        <ChapterNavBtn
          type="previous"
          // ClassName="hide"
          Link="/B1/"
          Text="如何測量"
        />
        <button className="btn_next" onClick={handleSubmit}>
          <a>填寫完成</a>
        </button>
      </div>
    </>
  )
}

C3FamilyMemberListPage.propTypes = {}

export default C3FamilyMemberListPage
