import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import { navigate } from "@reach/router"

import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"
import cx from "classnames"
import FormQuestionnaireAfter from "./form/FormQuestionnaireAfter"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isTriggerSubmit: false }
    this.quiz_answer = {
      quiz_student: "",
    }
  }

  setIsTriggerSubmit = () => {
    this.setState({ isTriggerSubmit: false })
  }

  handleSubmit = () => {
    this.setState({ isTriggerSubmit: true })
  }

  handleSubmitSuccess = () => {
    navigate("/E0")
  }

  checkQuizResult = (ans, errors) => {
    var sub_title = document.querySelector(".sub_title")

    if (
      this.quiz_answer.quiz_student === "1" &&
      !errors &&
      ans &&
      Object.keys(ans).length === 4
    ) {
      sub_title.classList.add("hide")
    } else {
      if (this.quiz_answer.quiz_student === "1") {
        sub_title.innerHTML = "還有家長題喔"
      }
      console.log(errors)

      if (
        this.quiz_answer.quiz_student !== "1" ||
        (errors &&
          Object.keys(errors).some(key => errors[key].type === "validate"))
      ) {
        sub_title.innerHTML = "試試看其他選項"
      }

      if (!this.quiz_answer.quiz_student) {
        sub_title.innerHTML = "還有學生題喔"
      }
      sub_title.classList.remove("hide")
    }
  }

  componentDidMount() {
    const quiz_student_item = document.querySelectorAll(" .quiz_student_item")
    const changeQuizStudent = e => {
      quiz_student_item.forEach(link => link.classList.remove("active"))
      e.target.parentNode.classList.add("active")
      this.quiz_answer.quiz_student = e.target.parentNode.dataset.answer
      // console.log(quiz_answer)
      this.checkQuizResult()
    }
    quiz_student_item.forEach(e =>
      e.addEventListener("click", changeQuizStudent)
    )
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="dialog_group dialog_personal dialog_quiz">
            <div className="dialog_item sty_a">
              <div className="dialog_header">
                <p className="main_title">健康挑戰題</p>
                <p className="sub_title hide">就差一點點！</p>
              </div>
              <div className="dialog_body">
                <div className="form">
                  <h6>學生題</h6>
                  <p>請選出下列哪一張圖不是量腰圍的步驟</p>
                  <div className="quiz_student">
                    <div
                      className="quiz_student_item quiz_student_item_1"
                      data-answer="0"
                    >
                      <img src={require("../images/b1_1/b1_1-1.svg")} alt="" />
                    </div>
                    <div
                      className="quiz_student_item quiz_student_item_2 answer"
                      data-answer="1"
                    >
                      <img src={require("../images/e2/E2_1-1.png")} alt="" />
                    </div>
                    <div
                      className="quiz_student_item quiz_student_item_3"
                      data-answer="0"
                    >
                      <img src={require("../images/b1_1/b1_1-3.svg")} alt="" />
                    </div>
                    <div
                      className="quiz_student_item quiz_student_item_4"
                      data-answer="0"
                    >
                      <img src={require("../images/b1_1/b1_1-2.svg")} alt="" />
                    </div>
                  </div>
                  <hr />
                  <h6>家長題</h6>
                  <p>請選出正確的成人腰圍標準</p>
                  <FormQuestionnaireAfter
                    isTriggerSubmit={this.state.isTriggerSubmit}
                    setIsTriggerSubmit={this.setIsTriggerSubmit}
                    onSubmitSuccess={this.handleSubmitSuccess}
                    checkQuizResult={this.checkQuizResult}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav center">
          <button className="btn_next" onClick={this.handleSubmit}>
            <a>完成挑戰</a>
          </button>
        </div>
      </>
    )
  }
}

export default Page
