import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap mobile_column">
          <div className="chapter_page_header">
            <h6 className="sub_title upper">守護家人健康</h6>
            <h2 className="page_title sty_c">文字篇</h2>
          </div>
          <div className="dialog_group dialog_family dialog_family_result">
            <div className="dialog_item sty_c">
              <div className="dialog_header">
                <p className="main_title">守護腰圍健康</p>
                <div className="sub_title">
                  <a href={require("../document/e1_1.pdf")} target="_blank">
                    下載懶人包↗
                  </a>
                </div>
              </div>
              <div className="dialog_body">
                <h6>居家檢測</h6>
                <p>腹部肥胖　血壓偏高</p>
                <br />
                <h6>醫院診斷</h6>
                <p>血糖偏高　HDL-C 偏低　三酸甘油酯偏高</p>
                <hr />
                <h6>5 項指標中含有 3 項或以上，則是代謝症候群。</h6>
              </div>
            </div>
            <div className="dialog_item sty_d">
              <div className="dialog_header">
                <p className="main_title">守護小腿圍健康</p>
                <div className="sub_title">
                  <a href={require("../document/e1_2.pdf")} target="_blank">
                    下載懶人包↗
                  </a>
                </div>
              </div>
              <div className="dialog_body">
                <h6>居家檢測</h6>
                <p>肌肉量較少</p>
                <br />
                <h6>醫院診斷</h6>
                <p>肌肉量較少 + 肌力不足 + 活動力較差</p>
                <hr />
                <h6>
                  測量小腿圍僅是評估肌少症風險的其中一項指標，65
                  歲以上長者可就醫診斷是否為肌少症。
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav between">
          <ChapterNavBtn
            type="previous"
            ClassName=""
            Link="/E0/"
            Text="守護健康"
          />
          <ChapterNavBtn type="next" ClassName="" Link="/E2/" Text="漫畫篇" />
        </div>
      </>
    )
  }
}

export default Page
