import React, { useRef, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useForm, useFieldArray } from "react-hook-form"
import { useNavigate } from "@reach/router"
import schoolStringData from "../SelectElementarySchool/school.json"

import FUC from "../FormUnstatedContainer.js"
import LiFamilyMember from "./LiFamilyMember"

import postHealthData from "../postHealthData.js"

import styForm from "../form.module.scss"
import sty from "./FormListOfFamily.module.scss"

// import Slider from "react-spring-slider"

import Slider from "react-slick"

const settings = {
  centerMode: true,
  // centerPadding: "60px",
  dots: false,
  // infinite: false,
  // arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        // centerPadding: "80px",
        slidesToShow: 1,
      },
    },
  ],
}

const inputRegisterConfig = {
  default: {
    required: true,
  },
}

const FormListOfFamily = ({
  isTriggerSubmit,
  setIsTriggerSubmit,
  onSubmitSuccess,
  onPostSuccess,
  onPostFail,
}) => {
  const navigate = useNavigate()

  const schoolList = JSON.parse(schoolStringData)

  const {
    formState,
    setBasicFromData,
    setBasicFromDataByInputOnChangeEvent,
    setListFormData,
    setListFormDataByInputOnChangeEvent,
  } = FUC.useContainer()

  if (!formState.school) {
    navigate("/C1")
  }

  const { register, control, handleSubmit, errors, unregister } = useForm({
    // defaultValues: {}; you can populate the fields by this attribute
  })

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "family",
  })

  const addOneMember = useCallback(
    (timeoutSec = 0) => {
      setTimeout(() => {
        append({
          title: "",
          gender: "",
          age: "",
          waist: "",
          calf: "",
          check: "",
        })
      }, timeoutSec * 100)
    },
    [append]
  )

  useEffect(() => {
    formState.family.forEach(({ title }, index) => {
      if (index >= 2) {
        // may be is come from initial
        title && addOneMember(index)
      } else {
        addOneMember(index)
      }
    })

    return () => {}
  }, [])

  const onSubmit = handleSubmit(data => {
    const isNameRegex = /^[\u0800-\u9fa5]{2,}/

    const finalData = {
      ...formState,
      ...data,
      schoolID: schoolList.find(
        ({ address, name }) =>
          RegExp(`(${formState.county})+.*(${formState.district})+`).test(
            address
          ) && name === formState.school
      )["id"],
      is_student_id_filled_by_name: isNameRegex.test(formState.name),
    } // remove family empty list

    if (!formState.studentID) {
      finalData.studentID = formState.name
    }

    setBasicFromData(finalData)
    postHealthData(finalData, { onSuccess: onPostSuccess, onFail: onPostFail })
    onSubmitSuccess()
  })

  useEffect(() => {
    if (isTriggerSubmit) {
      onSubmit()
      setIsTriggerSubmit(false)
    }

    return () => {}
  }, [isTriggerSubmit])

  const { family: errorFamily } = errors
  return (
    <form
      className={cx(styForm.form__default, sty.FormListOfFamily)}
      onSubmit={onSubmit}
    >
      {/* <ul> */}
      {/* <Slider> */}
      {fields.map((item, index) => {
        return (
          <LiFamilyMember
            key={`li${index}`}
            data={formState.family[index]}
            index={index}
            errors={errorFamily?.[index]}
            register={register}
            unregister={unregister}
            onChangeInput={setListFormDataByInputOnChangeEvent}
            removeCardByIndex={remove}
          />
        )
      })}
      {[...Array(4 - fields.length)].map((nulll, index) => {
        return (
          <li
            key={`empty${index}`}
            className={`dialog_item sty_b ${sty.empty}`}
            onClick={addOneMember}
          >
            <div className="dialog_header">
              <p className="main_title">新增成員</p>
            </div>
            <div className="dialog_body">
              <i className="addone">+</i>
            </div>
            {/* <button type="button" onClick={addOneMember}>
                  新增
                </button> */}
          </li>
        )
      })}
      {/* </Slider> */}
      {/* </ul> */}
    </form>
  )
}

FormListOfFamily.defaultProps = {
  onSubmitSuccess: () => {},
  onPostSuccess: () => {},
  onPostFail: () => {},
}

FormListOfFamily.propTypes = {
  isTriggerSubmit: PropTypes.bool,
  setIsTriggerSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onPostSuccess: PropTypes.func,
  onPostFail: PropTypes.func,
}

export default FormListOfFamily
