import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterPageHeader from "./ChapterPageHeader"
import ChapterNavBtn from "./ChapterNavBtn"
import { Link } from "gatsby"

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: true,
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    })
    // console.log(this.state.isChecked)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <div className="dialog_group dialog_agreement">
            <div className="dialog_item sty_a">
              <div className="dialog_header">
                <p className="main_title">請家長閱讀及同意以下說明</p>
                {/* <p className="sub_title">請家長閱讀</p> */}
              </div>
              <div className="dialog_body">
                <p>
                  本網站收集的量測結果並非來自專業醫護人員之健康檢查，數據僅供參考，本活動是以教學為目的，提倡居家自我檢測。勾選以下同意選項時，即代表本人
                  <a
                    href={require("../document/c0_agreement.pdf")}
                    target="_blank"
                  >
                    已詳閱此蒐集個人資料告知事項↗
                  </a>
                  並同意學童得進行本網站活動及資料填寫。
                </p>
                <div className="agreement_box">
                  <label htmlFor="agreement_input">
                    <input
                      type="checkbox"
                      name="agreement_input"
                      id="agreement_input"
                      className="agreement_input"
                      checked={!this.state.isChecked}
                      onChange={this.toggleChange}
                    />
                    <div className="agreement_note">本人已同意以上事項</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_nav page_nav center">
          <ChapterNavBtn
            type="next"
            ClassName={!this.state.isChecked ? "ischeck" : "uncheck"}
            Link="/C1/"
            Text="下一步"
          />
        </div>
      </>
    )
  }
}

export default Page
