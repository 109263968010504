import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterNavBtn from "./ChapterNavBtn"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const start_anime = document.querySelectorAll(".start_anime")

    gsap.from(start_anime, {
      opacity: 0,
      scale: 0.8,
      translateX: "50%",
      translateY: "50%",
      direction: 2,
      ease: Expo.easeOut,
      delay: 0.3,
    })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap mobile_column">
          <article className="document_wrap">
            <h1>關於</h1>
            <h4>主辦單位</h4>
            <p>
              有感於國家醫療支出增加、高齡化社會來臨，統一企業與統一超商於2003年捐助成立「財團法人千禧之愛健康基金會」，邀請十多位醫學、營養、食品科學之專家學者共同投入預防醫學社會教育。千禧之愛健康基金會主要關注領域為影響國人健康甚鉅的生活習慣病，包含「代謝症候群」與「肌少症」防治，期望透過深化預防重於治療觀念，使國人之飲食與生活型態更為健康。
            </p>
            <div className="logo_list">
              <a
                href="https://www.1000-love.org.tw/"
                className="logo_item"
                target="_blank"
              >
                <img
                  src={require("../images/logo_a.jpg")}
                  alt="財團法人千禧之愛健康基金會"
                />
              </a>
            </div>
            <hr />
            <h4>公益夥伴</h4>
            <p>
              <strong>「每一天，都要來點陽光」</strong>
              統一陽光長期支持千禧之愛健康基金會「健康出動到校園」活動，將健康促進及營養觀念帶入校園向下紮根，培訓兒童成為家庭健康長，主動關心一家人的健康。統一陽光堅持使用歐盟非基改標準、食品級黃豆，不添加豆粉、香料、防腐劑，是提供優質植物性蛋白質、大豆異黃酮與大豆卵磷脂等營養素最佳來源的植物飲。
            </p>
            <div className="logo_list">
              <a
                href="https://www.uni-president.com.tw/"
                className="logo_item"
                target="_blank"
              >
                <img src={require("../images/logo_c.jpg")} alt="統一企業" />
              </a>
              <a
                href="https://www.uni-sunshine.com.tw/"
                className="logo_item"
                target="_blank"
              >
                <img src={require("../images/logo_b.jpg")} alt="統一陽光" />
              </a>
            </div>
            <hr />
            <div className="logo_list last">
              <img
                src={require("../images/logo_d-2.jpg")}
                alt=""
                className="logo_item"
              />
            </div>
          </article>
        </div>

        {/* <div className="family_character_anime chapter_a1">
          <div className="char_item char_open_a"></div>
          <div className="char_item char_open_b"></div>
        </div> */}

        <div className="footer_nav page_nav center">
          <ChapterNavBtn type="next" ClassName="center" Link="/" Text="首頁" />
        </div>
        {/* <Link to="/page/">Page Link</Link> */}
      </>
    )
  }
}

export default Page
