/* eslint-disable */
import React, { useState } from "react"
import { createContainer } from "unstated-next"

const initialFamilyMember = {
  title: "",
  gender: "",
  age: "",
  waist: "",
  calf: "",
  confirm: "",
}

const defaultState = {
  studentID: "",
  name: "",
  county: "",
  district: "",
  school: "",
  tall: "",
  weight: "",
  // before_q1_1: "",
  // before_q1_2: "",
  // before_q2_1: "",
  // before_q2_2: "",
  // after_q1_1: "",
  // after_q1_2: "",
  // after_q2_1: "",
  // after_q2_2: "",
  // year: new Date().getFullYear(),
  family: [
    { ...initialFamilyMember },
    { ...initialFamilyMember },
    { ...initialFamilyMember },
    { ...initialFamilyMember },
  ],
}

function useFormState(initialState = defaultState) {
  const [formState, setForm] = useState(initialState)

  const setBasicFromData = state => {
    // console.log(state)
    setForm(prevState => ({
      ...prevState,
      ...state,
    }))
  }

  const setBasicFromDataByInputOnChangeEvent = e => {
    e.preventDefault()
    const { value, name } = e.target

    setBasicFromData({ [name]: value })
  }

  const setListFormData = (state, index) => {
    setForm(prevState => {
      const targetFamily =
        index > prevState.family.length - 1
          ? [...prevState.family, initialFamilyMember]
          : prevState.family
      return {
        ...prevState,
        family: targetFamily.map((memberState, indexList) =>
          indexList == index
            ? Object.assign({}, memberState, state)
            : memberState
        ),
      }
    })
  }

  const setListFormDataByInputOnChangeEvent = e => {
    e.preventDefault()
    const {
      value,
      dataset: { index, name },
    } = e.target

    setListFormData({ [name]: value }, index)
  }

  return {
    formState,
    setBasicFromData,
    setBasicFromDataByInputOnChangeEvent,
    setListFormData,
    setListFormDataByInputOnChangeEvent,
  }
}

const FormStateContainer = createContainer(useFormState)

export default FormStateContainer
