import React, { useRef, useState, useCallback, useEffect } from "react"

import { gsap, Expo } from "gsap"
import ChapterTitle from "./ChapterTitle"
import ChapterPageHeader from "./ChapterPageHeader"
import ChapterNavBtn from "./ChapterNavBtn"
// import Slick from "slick-carousel"
import Slider from "react-slick"

const settings = {
  centerMode: true,
  // centerPadding: "60px",
  dots: false,
  // infinite: false,
  // arrows: false,
  speed: 500,
  slidesToShow: 3,
  // slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
  ],
}

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var comic_slider = document.querySelector(".comic_slider")
    var comic_slider_inner = comic_slider.querySelectorAll(".slick-list")
    // var comic_slider_timeline = gsap.timeline()
    gsap.from(comic_slider, {
      opacity: 0,
      ease: Expo.easeOut,
      duration: 1,
      delay: 0.3,
    })

    gsap.from(comic_slider_inner, {
      opacity: 0,
      translateX: "30%",
      ease: Expo.easeOut,
      duration: 2,
      delay: 0.5,
    })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="chapter_wrap center">
          <ChapterPageHeader
            ClassName="sty_a"
            UpperTitle="二十歲以上成人"
            PageTitle="腰圍八九十，健康常維持"
            LowerTitle="超出正常值即為腹部肥胖"
          />
          {/* <div className="chapter_page_header">
            <h6 className="sub_title upper">二十歲以上成人</h6>
            <h2 className="page_title sty_a">腰圍八九十，健康常維持</h2>
            <h6 className="sub_title lower">超出正常值即為腹部肥胖</h6>
          </div> */}

          <div className="chapter_content">
            <div className="comic_slider remove_item_4">
              <Slider {...settings}>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_1-1.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">手拿量尺雙手環抱</div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_1-2.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">
                    雙手將量尺沿著腰圍拉開，並通過肚臍
                  </div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_1-3.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">
                    量尺保持水平，並檢視刻度即是腰圍標準
                  </div>
                </div>
                <div className="comic_item">
                  <img
                    src={require("../images/b1_1/b1_1-4.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                  <div className="comic_note">
                    謹記腰圍八九十，健康常維持！判斷是否超標。
                  </div>
                </div>
                <div className="comic_item hide">
                  <img
                    src={require("../images/b1_1/b1_1-4.svg")}
                    alt="抱"
                    className="comic_img"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="footer_nav page_nav between">
          <ChapterNavBtn
            type="previous"
            ClassName="hide"
            Link="/B1_1/"
            Text="上一步"
          />
          <ChapterNavBtn
            type="sec"
            ClassName=""
            Link="/B1_2/"
            Text="我要看小腿圍"
          />
          <ChapterNavBtn type="next" ClassName="" Link="/C0/" Text="下一步" />
        </div>
      </>
    )
  }
}

export default Page
